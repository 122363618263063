import React from 'react';
import {
	Heart,
	FacebookIcon,
	TwitterIcon,
	InstagramIcon,
	AddIcon,
	CloseIcon,
	ArrowUp,
	MenuIcon,
	RemoveIcon,
	ChevronBackOutline,
	ChevronRight,
	ChevronForward,
	ChevronDown,
	ShoppingBag,
	UserIcon,
	SearchIcon,
	CloseCircleIcon 
} from './icons';

type Props = {
	name: string;
};

const CategoryIcon: React.FC<Props> = ({ name }) => {
	let icon;
	switch (name) {
		case 'heart':
			icon = <Heart />;
			break;
		case 'facebook':
			icon = <FacebookIcon />;
			break;
		case 'twitter':
			icon = <TwitterIcon />;
			break;
		case 'instagram':
			icon = <InstagramIcon />;
			break;
		case 'add':
			icon = <AddIcon />;
			break;
		case 'close':
			icon = <CloseIcon />;
			break;
		case 'close-circle':
			icon = <CloseCircleIcon />;
			break;
		case 'arrow-up':
			icon = <ArrowUp />;
			break;
		case 'menu':
			icon = <MenuIcon />;
			break;
		case 'remove':
			icon = <RemoveIcon />;
			break;
		case 'chevron-back':
			icon = <ChevronBackOutline />;
			break;
		case 'chevron-right':
			icon = <ChevronRight />;
			break;
		case 'chevron-forward':
			icon = <ChevronForward/>;
			break;
		case 'chevron-down':
			icon = <ChevronDown />;
			break;
		case 'shopping':
			icon = <ShoppingBag />;
			break;
		case 'user':
			icon = <UserIcon />;
			break;
		case 'search':
			icon = <SearchIcon  />;
			break;
		default:
			icon = null;
			break;
	}
	return <>{icon}</>;
};

export default CategoryIcon;
