import { keyframes } from "@emotion/react";

const fadein = keyframes`
from{opacity:0; }to{opacity:1; visibility:visible;}
}
`;

const fadeout = keyframes`
from{
    opacity:1; }to{opacity:0; visibility:hidden;}
}
`;

const styles = {
  buttonStyles: {
    position: "fixed",
    bottom: "20px",
    right: "30px",
    zIndex: "99",
    border: "none",
    outline: "none",
    backgroundColor: "#054752 !important",
    color: "white",
    cursor: "pointer",
    padding: "15px",
    borderRadius: "50% !important",
    fontSize: "18px",
    height: "32px",
    width: "32px",
    minWidth: 0,
    minHeight: 0,
    svg: {
      width: "24px",
      height: "24px",
      position: "fixed",
      bottom: "24px",
      right: "34px",
    },
    "&.fadein": {
      transition: ".2s all ease",
      animation: `.4s ${fadein} ease-in-out forwards`,
    },
    "&.fadeout": {
      transition: ".2s all ease",
      animation: `.4s ${fadeout} ease-in-out forwards`,
    },
  },
};

export default styles;
