const styles = {
  footer: {
    padding: "40px 80px",
    "text-align": "center",
    position: "relative",
    justifyContent: "space-between",
    height: "355px",
    backgroundColor: "cgTeal",
    color: "#fff",
    "@media screen and (max-width: 1400px)": {
      height: "400px",
      padding: "20px 40px",
    },
    "@media screen and (max-width: 590px)": {
      height: "400px",
    },
    "@media screen and (max-width: 410px)": {
      height: "470px",
    },
    ".container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    ".sticky": {
      position: "sticky",
      bottom: 0,
      width: "100%",
    },
    h1: {
      width: ["100%", "20%"],
      padding: [1, 15],
      "text-align": "center",
      fontSize: ["18px", "24px"],
    },
  },
  links: {
    listStyle: "none",
    display: "flex",
    "flex-wrap": "wrap",
    justifyContent: "center",
    marginBottom: "45px",
    "@media screen and (max-width: 590px)": {
      padding: "0 15px",
      marginBottom: "10px",
    },

    li: {
      margin: "0 25px",
      "@media screen and (max-width: 590px)": {
        margin: "0 10px",
        paddingBottom: "0"
      },
    },
    a: {
      font: "18px/50px 'Lora', Sans-serif",
      color: "#fff",
      textDecoration: "none",
      transition: "all 0.4s ease",

      "&:hover": {
        color: "#D79A2B",
      },
    },
  },
  newsletter: {
    marginBottom: "10px",
    "@media screen and (max-width: 576px)": {
      marginBottom: "5px",
    },
  },
  hr: {
    borderBottom: "3px solid white",
    padding: "15px 0",
  },
  social: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "15px",
    "@media screen and (max-width: 590px)": {
      justifyContent: "center",
      marginRight: "16px",
    },

    a: {
      color: "#fff",
      transition: "all 0.4s ease",
      "&.instagram": {
        order: "2",
      }
    },

    svg: {
      width: "32px",
      height: "32px",
      margin: "0 30px",
    },
  },
};

export default styles;
