const styles = {
  buttonStyle: {
    width: "100%",
    maxWidth: 750,
    lineHeight: "3",
    height: "70px",
    overflow: "hidden",
    overflowX: "hidden",
    backgroundColor: "transparent",
    transition: "0.4s all ease",
    "text-align": "center",
    "&:hover": {
      cursor: "pointer",
      color: "cgGold",
      borderColor: "cgGold",
      span: {
        "&::before, &::after": {
          borderColor: "cgGold",
        },
      },
    },
    span: {
      fontSize: "20px",
      width: "100%",
      position: "relative",
      img: {
        height: "48px",
        width: "48px",
        position: "absolute",
        top: "50%",
        left: "50%",
        "WebkitTransform": "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
        zIndex: "-1",
      },
      "&::before, &::after": {
        content: "''",
        transition: ".4s all ease",
        verticalAlign: "super",
        position: "absolute",
        height: "1px",
        borderBottom: "1px solid black",
        top: "50%",
        width: "600px",
      },
      "&::before": {
        right: "100%",
        marginRight: "15px",
      },
      "&::after": {
        left: "100%",
        marginLeft: "15px",
      },
    },
  },
};

export default styles;
