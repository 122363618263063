const styles = {
  searchResult: {
    position: "absolute",
    top: 140,
    height: "calc(100vh - 140px)",
    overflow: "auto",
    zIndex: 9999,
    width: "100%",
    left: 0,
    backgroundColor: "white",
    minHeight: "100vh",
    paddingX: 40,
    paddingTop: 40,
    paddingBottom: 230,
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "muted",
    transition: "all 0.4s ease",

    "@media only screen and (max-width: 1360px)": {
      paddingX: 30,
      paddingTop: 30,
    },
    "@media only screen and (max-width:1024px)": {
      top: 123,
      height: "calc(100vh - 123px)",
    },
    "@media only screen and (max-width: 480px)": {
      paddingX: 20,
      paddingTop: 20,
      paddingBottom: 150,
    },
    "&.notFound": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "flex-direction": "column",
      ">h1": {
        position: "relative",
        fontSize: ["25px", "35px", "50px"],
        letterSpacing: "4px",
        fontFamily: "heading",
        textTransform: "lowercase",
        fontStyle: "bold",
        marginTop: "0",
        marginBottom: "35px",
        zIndex: "10",
        ">img": {
          height: ["75px", "95px", "105px"],
          width: ["75px", "95px", "105px"],
          position: "absolute",
          bottom: "-20px",
          right: "-32px",
          zIndex: "-1",
        },
      },
      ">p": {
        fontSize: ["16px", "20px", "20px"],
        fontFamily: "body",
        "text-align": "center",
      },
    },
  },
  wrapper: {
    "@media screen and (max-width:890px)": {
      zIndex: 9997,
      paddingTop: "130px",
      paddingRight: "20px",
      backgroundColor: "white",
      position: "fixed",
      width: "100%",
    },
    "@media screen and (max-width:576px)": {
      paddingTop: "110px",
      paddingRight: "5px"
    },
    "@media screen and (max-width:448px)": {
      paddingTop: "70px",
    },
  },
  notFoundCard: {
    maxWidth: "1350px",
    margin: "0 auto !important",
    padding: "0 10px",
    "@media only screen and (min-width: 650px)": {
      padding: "0 25px",
    },
    "text-align": "center",
    img: {
      marginBottom: 30,
    },
    h2: {
      fontSize: 2,
      color: "primary",
      marginBottom: [12, 15],
    },
  },
};

export default styles;
