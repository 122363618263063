export const HasAvailableVariant = (product) => {
  for (const variant of product.variants) {
    // If any variant has 'availableForSale' set to true, return true
    if (variant.availableForSale === true) {
      return true;
    }
  }
  return false;
};

export const MakeMixedVariantArray = (products) => {
  if (!products) {
    return;
  }
  let singleVariant = {};
  let singleProduct = {};
  let productVariants = [];
  products.forEach((product) => {
    if (product.tags?.includes('ShowVariants')) {
      const { title, handle, variants } = product;
      variants.forEach((variant) => {
        singleVariant = {
          isVariant: true,
          title,
          handle,
          variants: product.variants,
          options: product.options,
          sku: variant.sku,
          id: variant.shopifyId,
          parentId: product.shopifyId,
          shopifyId: variant.shopifyId,
          price: variant.price,
          image: variant.image,
          available: variant.availableForSale,
          subtitle: variant.title,
        };
        productVariants.push(singleVariant);
      });
    } else {
      singleProduct = {
        isVariant: false,
        title: product.title,
        handle: product.handle,
        variants: product.variants,
        options: product.options,
        sku: product.variants[0].sku,
        id: product.variants[0].shopifyId,
        parentId: product.shopifyId,
        shopifyId: product.variants[0].shopifyId,
        price: product.variants[0].price,
        image: product.images[0],
        available: HasAvailableVariant(product),
        subtitle: '',
      };
      productVariants.push(singleProduct);
    }
  });
  return productVariants;
};

export const MakeVariantArray = (products) => {
  if (!products) {
    return;
  }
  let singleVariant = {};
  let productVariants = [];
  products.forEach((product) => {
    const { title, handle, variants } = product;
    variants.forEach((variant) => {
      singleVariant = {
        isVariant: product.variants.length > 1,
        title,
        handle,
        sku: variant.sku,
        id: variant.shopifyId,
        price: variant?.price,
        image: variant.image || product.images?.[0],
        available: variant.availableForSale,
        subtitle: variant.title,
      };
      productVariants.push(singleVariant);
    });
  });
  return productVariants;
};

export const TextCheck = (textObject) => {
  const filtered = textObject?.filter((block) => block.text !== '');
  const checked = filtered?.length > 0 ? filtered : null;
  return checked;
};

export const ArrayCheck = (array) => {
  const filtered = array?.filter((block) => block.element !== null);
  const checked = filtered?.length > 0 ? filtered : null;
  return checked;
};

export const ShopArrayCheck = (array) => {
  const filtered = array?.filter((block) => block.element?.document !== null);
  const checked = filtered?.length > 0 ? filtered : null;
  return checked;
};
