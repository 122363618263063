/** @jsx jsx */
import { StaticQuery, graphql } from "gatsby";
import { useState, useContext, useEffect, useRef, FC } from "react";
import { Box, Text, Button, jsx } from "theme-ui";
import Drawer from "rc-drawer";
import "rc-drawer/assets/index.css";
import Logo from "../../../logo/logo";
import Search from "../../../search";
import { Link } from "gatsby";
import Sidebar from "../sidebar/sidebar";
import DrawerCart from "../../../drawer-cart/drawer-cart";
import { LocalCartContext } from "../../../../provider/local-cart-provider";
import useWindowSize from "../../../../hooks/useWindowSize";
import useOnClickOutside from "../../../../hooks/useOutsideClick";
import styles from "./header-modern.style";
import Icon from "../../../category-icon";

const menuStaticQuery = graphql`
  query {
    allPrismicHomeminimal {
      edges {
        node {
          data {
            stories_menu {
              story_category {
                document {
                  ... on PrismicStoryType {
                    data {
                      name
                    }
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface HeaderProps {
  setSearched: (searched: boolean) => void;
  fluid?: boolean;
  homeLink?: string;
  pathPrefix?: string;
  showNoticeBar?: boolean;
}

const Header: FC<HeaderProps> = ({ setSearched }) => (
  <StaticQuery<GatsbyTypes.Query>
    query={`${menuStaticQuery}`}
    render={({ allPrismicHomeminimal }) => {
      const storiesMenu =
        allPrismicHomeminimal.edges[0].node.data?.stories_menu;
      const [searchBarBelow, setSearchBarBelow] = useState(false);
      const [sidebarVisibile, setSidebarVisible] = useState(false);
      const [mobileSearch, setMobileSearch] = useState(false);
      const mobileSearchRef = useRef(null!);
      useOnClickOutside(mobileSearchRef, () => setMobileSearch(false));
      const { products, cartVisible, setCartVisible } =
        useContext(LocalCartContext);
      const totalProducts = products
        .map((item) => item.quantity)
        .reduce((prev, curr) => prev + curr, 0);

      const windowSize = useWindowSize();
      useEffect(() => {
        if (windowSize && windowSize < 890) {
          setSearchBarBelow(true);
        } else setSearchBarBelow(false);
      }, [windowSize]);

      const handleClose = () => {
        setCartVisible(false);
      };

      return (
        <Box sx={styles.container}>
          <Box as="header" sx={styles.wrapper}>
            <div sx={styles.fragment}>
              <Button
                variant="text"
                sx={styles.hamburgBtn}
                onClick={() => setSidebarVisible(true)}
                aria-label="Hamburg menu"
              >
                <Icon name="menu" />
              </Button>
              <div id="buttons" sx={styles.homebuttons}>
                <Link className="main-link" activeClassName="active" to="/farm">
                  FARM
                </Link>
                <Link
                  className="main-link"
                  activeClassName="active"
                  to="/cgfood"
                >
                  FOOD
                </Link>
                <Link
                  className="main-link"
                  activeClassName="active"
                  to="/events"
                >
                  EVENTS
                </Link>
                <Link
                  id="shop"
                  className="main-link"
                  activeClassName="active"
                  to="/shop"
                >
                  STORE
                </Link>
                <Link className="main-link" activeClassName="active" to="/cge">
                  CGE
                </Link>
              </div>
              <Logo path="/" />
              <div id="buttons-right" sx={styles.homeRightButtons}>
                <Box sx={styles.dropdown}>
                  <div className="about-wrap">
                    <a id="about" className="about-link">
                      About
                    </a>
                    <Icon name="chevron-down" />
                  </div>
                  <div className={"dropdown-content"}>
                    <Link to="/mission">Mission</Link>
                    <Link to="/team">Team</Link>
                    <Link to="/about-campus">Campus</Link>
                    <Link to="/incubator">CG Foundation</Link>
                    <Link to="/media">Media</Link>
                  </div>
                </Box>
                <Box sx={styles.dropdown}>
                  <div className="about-wrap">
                    <a id="about" className="about-link">
                      Visit
                    </a>
                    <Icon name="chevron-down" />
                  </div>
                  <div className={"dropdown-content"}>
                    {/* <Link to="/weddings">Weddings</Link> */}
                    <Link to="/plan-your-visit">Plan your visit</Link>
                    <Link to="/venue">Book A Venue</Link>
                    {/* <Link to="/farm-tours">Farm Tours</Link> */}
                    <Link to="/stone-dam">Stone Dam</Link>
                    {/* <Link to="/private-events-at-common-ground">
                      Private Events
                    </Link> */}
                  </div>
                </Box>
                {/* <Box sx={styles.dropdown} className="stories">
                  <div className="about-wrap">
                    <a id="storiest" className="about-link">
                      Topics
                    </a>
                    <Icon name="chevron-down" />
                  </div>
                  <div className={"dropdown-content"}>
                    {storiesMenu?.map((item) => {
                      const uid = item?.story_category?.document?.uid;
                      const name = item?.story_category?.document?.data?.name;
                      return (
                        <Link key={uid} to={`/${uid}`}>
                          {name}
                        </Link>
                      );
                    })}
                  </div>
                </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    marginLeft: "15px",
                  }}
                >
                  <Link
                    className="main-link"
                    activeClassName="active"
                    to="/impressions"
                  >
                    Gallery
                  </Link>
                </Box>
                <Box sx={styles.icons}>
                  {!searchBarBelow ? (
                    <Search setSearched={setSearched} />
                  ) : null}
                  <a
                    className="account-icon"
                    href={`https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/account`}
                    rel="noopener noreferrer"
                    target="_blank"
                    title="My account"
                  >
                    <Icon name="user" />
                  </a>
                  <Button
                    className="cart-icon"
                    variant="text"
                    aria-label="Cart"
                    onClick={() => setCartVisible(true)}
                  >
                    <Icon name="shopping" />
                    <Text sx={styles.badge}>{totalProducts}</Text>
                  </Button>
                </Box>
              </div>
            </div>
          </Box>
          {searchBarBelow ? <Search setSearched={setSearched} /> : null}
          <Drawer
            level={null}
            width="100%"
            open={sidebarVisibile}
            handler={false}
            placement="left"
            onClose={() => setSidebarVisible(false)}
          >
            <Sidebar
              onClose={() => setSidebarVisible(false)}
              storiesMenu={storiesMenu}
            />
          </Drawer>
          <DrawerCart
            open={cartVisible}
            onClick={() => handleClose()}
            onClose={() => handleClose()}
            products={products}
          />
        </Box>
      );
    }}
  />
);

export default Header;