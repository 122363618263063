/** @jsx jsx */
import { Link, StaticQuery, graphql } from 'gatsby';
import { Box, Image, jsx } from 'theme-ui';
import styles from './logo.style';

type PropsType = {
	imgSrc?: string;
	imgAlt?: string;
	path?: string;
};

const logoStaticQuery = graphql`
	query {
		allPrismicCommon {
  	  edges {
  	    node {
  	      data {
  	        logo {
  	          url
							alt
  	          localFile {
  	            publicURL
  	          }
  	        }
  	      }
  	    }
  	  }
  	}
	}
`;

const Logo: React.FC<PropsType> = ({ imgSrc, imgAlt, path }) => {
	return (
		<StaticQuery<GatsbyTypes.Query>
			query={`${logoStaticQuery}`}
			render={({ allPrismicCommon }) => {
				const logo = allPrismicCommon.edges[0].node.data.logo
				if (path) {
					return (
						<Box className="logo" sx={styles.wrapper}>
							<Link to={path} aria-label="Home">
								<Image
									sx={styles.logo}
									src={imgSrc ? imgSrc : logo?.url}
									alt={imgAlt ? imgAlt : logo?.alt}
								/>
							</Link>
						</Box>
					);
				} else return (
					<Box className="logo" sx={styles.wrapper}>
							<Image
								src={imgSrc ? imgSrc : logo?.url}
								alt={imgAlt ? imgAlt : logo?.alt}
							/>
					</Box>
				);
			}}
		/>
	);
};

export default Logo;
