const styles = {
  wrapper: {
    position: "fixed" as "fixed",
    zIndex: "9998",
    padding: "30px",
    width: "100vw",
    display: "flex",
    backgroundColor: "white",
    alignItems: "flex-end",
    "@media screen and (max-width: 576px)": {
      padding: "15px",
      height: "80px",
      margin: "20px auto 0 auto",
      position: "fixed" as "fixed",
      top: "0",
    },
    "@media only screen and (max-width: 890px)": {
      ">.picksySearch": {
        width: "100%",
      },
    },
    ".picksySearch": {
      input: {
        border: "0",
        backgroundColor: "lightgray",
        transition: "background-color 0.2s ease",
        margin: "0 30px",
        "&:focus": {
          backgroundColor: "muted",
        },
      },
    },
  },
  fragment: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: "100%",
    "@media only screen and (max-width: 1280px)": {
      justifyContent: "flex-start",
    },
    "@media screen and (max-width: 576px)": {
      width: "73%",
    },
  },
  container: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    "flex-direction": "column",
    ".drawer-open": {
      zIndex: "99999",
    },
    "@media screen and (max-width: 576px)": {
      position: "relative" as "relative",
    },
  },
  ".active": {
    color: "cgGold",
  },
  mobileSearch: {
    width: "100%",
  },
  hamburgBtn: {
    flex: 1,
    color: "#041C2C",
    display: "none !important",
    paddingRight: "0 !important",
    "@media only screen and (max-width: 1280px)": {
      display: "inline-flex !important",
      marginRight: "0",
      backgroundColor: "transparent",
      justifyContent: "flex-start !important",
      button: {},
      svg: {
        color: "#041C2C",
        stroke: "#041C2C",
        height: "22px",
        width: "22px",
        transition: "all 0.2s ease",
        "&:hover": {
          stroke: "#D79A2B",
        },
        "&:focus": {
          stroke: "#D79A2B",
        },
      },
    },
  },
  searchBtn: {
    display: "inline-flex !important",
    "@media only screen and (max-width: 991px)": {
      display: "inline-flex !important",
    },
  },
  homebuttons: {
    transition: "all 0.3s ease",
    ".active": {
      color: "cgGold",
    },
    flex: 1,
    ".main-link": {
      marginRight: "20px",
      marginLeft: "20px",
      "&:first-of-type": {
        marginLeft: "0",
      },
    },
    a: {
      paddingBottom: "5px",
      textDecoration: "none",
      color: "#535F61",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "400",
      transition: "all 0.3s ease",
      "&:hover": {
        color: "#D79A2B !important",
      },
      "&:focus": {
        color: "#D79A2B !important",
      },
    },
    "@media only screen and (max-width: 1280px)": {
      display: "none",
    },
  },
  homeRightButtons: {
    ".active": {
      color: "cgGold",
    },
    flex: 1,
    display: "flex",
    "flex-direction": "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    ".main-link": {
      marginRight: "20px",
      marginLeft: "20px",
      "&:first-of-type": {
        marginLeft: "0",
      },
    },
    a: {
      paddingBottom: "5px",
      textDecoration: "none",
      color: "#535F61",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "400",
      transition: "all 0.3s ease",
      "&:hover": {
        color: "#D79A2B !important",
      },
      "&:focus": {
        color: "#D79A2B !important",
      },
    },
    "@media only screen and (max-width: 1280px)": {
      display: "none",
    },
  },
  icons: {
    display: "flex",
    "align-items": "flex-end",
    "justify-content": "flex-end",
    "margin-left": "70px",
    "margin-top": "30px",
    color: "primary",
    a: {
      color: "inherit",
    },
    svg: {
      color: "#041C2C",
      transition: "all 0.3s ease",
      "&:hover": {
        color: "#D79A2B !important",
      },
      "&:focus": {
        color: "#D79A2B !important",
      },
    },
    ".account-icon": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "1px",
      marginLeft: [0, "3px"],
      svg: {
        width: "25px",
        height: "25px",
      },
    },
    ".cart-icon": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "10px",
      "@media screen and (max-width: 890px)": {
        marginLeft: "10px",
      },
      svg: {
        width: "25px",
        height: "25px",
      },
    },
    button: {
      display: "flex",
      position: "relative" as "relative",
      height: "26px",
      width: "26px",
      justifyContent: "center",
      alignItems: "center",
      outline: "none",
      padding: 0,
      "&:first-of-type": {
        order: "2" as any,
      },
      "@media only screen and (max-width: 890px)": {
        minWidth: "25px",
      },
    },
    "& button": {
      backgroundColor: "transparent",
      "&:hover": {
        div: {
          color: "#D79A2B",
        },
        cursor: "pointer",
        svg: {
          color: "#D79A2B",
        },
        span: {
          color: "#D79A2B",
        },
      },
    },
  },

  badge: {
    color: "#041C2C",
    fontSize: "11px",
    lineHeight: "1",
    fontWeight: "700",
    backgroundColor: "white",
    position: "absolute" as "absolute",
    top: "26px",
    transition: "all 0.3s ease",
  },
  dropdown: {
    position: "relative" as "relative",
    display: "inline-block",
    svg: {
      width: "21px",
      height: "21px",
      marginLeft: "6px",
      marginBottom: "5px",
      transition: "all 0.3s ease",
      cursor: "pointer",
    },
    ".about-link": {
      cursor: "pointer",
      marginLeft: "15px",
      "@media only screen and (min-width: 1280px)": {
        marginLeft: "20px",
      },
    },
    ".about-wrap": {
      display: "flex",
      alignItems: "center",
    },
    ".dropdown-content": {
      padding: "8px 6px 11px",
      display: "none",
      position: "absolute" as "absolute",
      marginLeft: "9px",
      backgroundColor: "#fff",
      minWidth: "175px",
      boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.2)",
      zIndex: 50000,
      a: {
        color: "subHeadingColor",
        padding: "8px 16px",
        textDecoration: "none",
        display: "block",
        fontSize: "17px",
      },
      "&.open": {
        display: "block",
      },
    },
    "&.stories": {
      marginRight: "0px",
    },
    ":hover": {
      ".dropdown-content": {
        display: "block",
      },
      svg: {
        color: "#D79A2B",
      },
      ".about-link": {
        color: "#D79A2B",
      },
    },
  },
};

export default styles;
