/** @jsx jsx */
import React, { Fragment } from "react";
import { Box, jsx } from "theme-ui";
import ProductCardWithLink from "../product-card/with-link";
import styles from "./product-slider.style";
import Carousel from "../carousel/carousel";

type Props = {
  data: any;
  related?: boolean;
  maxThree?: boolean;
  size?: string;
  handleVariantChange?: any;
  newshop?: boolean;
};

const VariantSlider: React.FC<Props> = ({
  data,
  related,
  maxThree,
  size,
  newshop,
  handleVariantChange,
}) => {
  let responsive = {};
  if (newshop) {
    responsive = {
      desktop: {
        breakpoint: { max: 100000000, min: 1350 },
        items: 5,
        partialVisibilityGutter: 0,
      },
      ipadpro: {
        breakpoint: { max: 1349, min: 1090 },
        items: 4,
        partialVisibilityGutter: 20,
      },
      ipad: {
        breakpoint: { max: 1089, min: 768 },
        items: 3,
        partialVisibilityGutter: 30,
      },
      smipad: {
        breakpoint: { max: 767, min: 600 },
        items: 2,
        partialVisibilityGutter: 30,
      },
      mobile: {
        breakpoint: { max: 599, min: 0 },
        items: 1,
        partialVisibilityGutter: 20,
      },
    };
  }
  if (maxThree) {
    responsive = {
      desktop: {
        breakpoint: { max: 100000000, min: 826 },
        items: 3,
      },
      ipadpro: {
        breakpoint: { max: 825, min: 601 },
        items: 2,
        partialVisibilityGutter: 30,
      },
      mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
        partialVisibilityGutter: 60,
      },
    };
  }
  if (!newshop && !maxThree) {
    responsive = {
      desktop: {
        breakpoint: { max: 100000000, min: 1330 },
        items: 4,
      },
      ipadpro: {
        breakpoint: { max: 1329, min: 1061 },
        items: 3,
      },
      ipad: {
        breakpoint: { max: 1060, min: 840 },
        items: 2,
      },
      smipad: {
        breakpoint: { max: 839, min: 601 },
        items: 2,
        partialVisibilityGutter: 30,
      },
      mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
        partialVisibilityGutter: 40,
      },
    };
  }

  const getPrice = (price: any) =>
    Intl.NumberFormat(undefined, {
      currency: "USD",
      minimumFractionDigits: 2,
      style: "currency",
    }).format(parseFloat(price || 0));
  const relatedProducts = related ? "related" : "";
  const hideButtons =
    newshop && data?.length <= 5 ? "hide-over" : newshop ? "hide-under" : "";

  return (
    <Box className={`${relatedProducts}`} sx={styles.wrapper}>
      <Carousel
        ssr={true}
        visible={true}
        responsive={responsive}
        gutter="0"
        showDots={false}
        hideButtons={hideButtons}
      >
        {data.map((variant: any) => {
          const { available, id, handle, price } = variant;
          if (available) {
            return (
              <Fragment key={id}>
                <ProductCardWithLink
                  shopPage={true}
                  size={size}
                  id={id}
                  path={`/product/${handle}`}
                  price={getPrice(price)}
                  handleVariantChange={handleVariantChange}
                  product={variant}
                />
              </Fragment>
            );
          }
        })}
      </Carousel>
    </Box>
  );
};

export default VariantSlider;
