/** @jsx jsx */
import React, { useContext } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Box, Flex, Text, jsx } from "theme-ui";
import { ProductContext } from "../../provider/product-provider";
import Button from "../button/outline-button";
import styles from "./product-card.style";
import { Product } from "../product-buy/types";
import { RiShoppingCartLine } from "react-icons/ri";

type PropsType = {
  price: any;
  path: string;
  shopPage?: boolean;
  id?: string;
  size?: string;
  handleVariantChange?: any;
  search?: boolean;
  contentGrid?: boolean;
  blogStyle?: boolean;
  product?: Product;
};

const ProductCardWithLink: React.FC<PropsType> = (props) => {
  const {
    path,
    size,
    price,
    shopPage,
    handleVariantChange,
    search,
    contentGrid,
    blogStyle,
    product,
  } = props;
  //const { openModal } = useContext(ProductContext);

  const { addVariantToCart, setCurrentVariant } = useContext(ProductContext);

  const handleOpenModal = () => {
    setCurrentVariant(product);
    addVariantToCart(product);
  };

  const image = {
    local: product?.image ?? null,
    original: product?.image?.originalSrc ?? null,
  };

  const urlParam = shopPage && product?.isVariant ? `?id=${product?.id}` : "";
  const productLink = path + urlParam;

  if (search) {
    return (
      <Box sx={styles.wrapper} className="search-wrap">
        <Box className="extra-small" sx={styles.imageWrapper}>
          {image && (
            <Link
              to={productLink}
              /* onClick={() => handleVariantChange(product?.id)} */
            >
              {image.local ? (
                <GatsbyImage
                  image={image.local.gatsbyImageData}
                  alt={product?.title || `Product`}
                />
              ) : image.original ? (
                <img
                  className="product-image"
                  src={image.original}
                  alt={product?.title}
                />
              ) : null}
            </Link>
          )}
          <Box sx={styles.cart} />
        </Box>
        <Link to={productLink} /* onClick={() => handleVariantChange(product?.id)} */>
          <Flex className={`content ${shopPage ? "shop" : ""}`}>
            <div>
              <Text sx={styles.title} className="title">
                {product?.title}
              </Text>
              {product?.subtitle !== "Default Title" && (
                <span sx={styles.subtitle}>{product?.subtitle}</span>
              )}
            </div>
          </Flex>
        </Link>
      </Box>
    );
  }
  if (blogStyle) {
    return (
      <Box sx={styles.blogStyleWrapper}>
        <Box className="xl" sx={styles.imageWrapper}>
          {image.local ? (
            <GatsbyImage
              image={image.local.gatsbyImageData}
              alt={product?.title || `Product-Image`}
            />
          ) : image.original ? (
            <img
              className="product-image"
              src={image.original}
              alt={product?.title}
            />
          ) : null}
        </Box>
        <Flex className="meta-wrap">
          <Flex className="sub-meta">
            <div>
              <Text sx={styles.title} className="title">
                {product?.title}
              </Text>
              {product?.subtitle !== "Default Title" && (
                <span sx={styles.subtitle} className="blog-style">
                  {product?.subtitle}
                </span>
              )}
            </div>
            <div>
              <Box sx={styles.column}>
                <Text sx={styles.price} className="blog-style">
                  {price}
                </Text>
                <Text>$ US</Text>
              </Box>
            </div>
          </Flex>
          <Link to={productLink}>
            <Button text="SHOP NOW" />
          </Link>
        </Flex>
      </Box>
    );
  } else {
    return (
      <Box sx={styles.wrapper} className={size}>
        <Box className={size} sx={styles.imageWrapper}>
          {image && (
            <Link
              /* onClick={() => handleVariantChange(product?.id)} */
              to={productLink}
            >
              {image.local ? (
                <GatsbyImage
                  image={image.local.gatsbyImageData}
                  alt={product?.title || `Product-Image-Alt`}
                />
              ) : image.original ? (
                <img
                  className="product-image"
                  src={image.original}
                  alt={product?.title}
                />
              ) : null}
            </Link>
          )}
          <Box sx={styles.cart} />
        </Box>

        <Flex className={`content ${shopPage ? "shop" : ""}`}>
          <Link to={productLink}>
            <div>
              <Text
                sx={styles.title}
                className={contentGrid ? "content-grid title" : "title"}
              >
                {product?.title}
              </Text>
              {product?.subtitle !== "Default Title" && (
                <span sx={styles.subtitle}>{product?.subtitle}</span>
              )}
            </div>
          </Link>
          {!contentGrid && (
            <Flex sx={styles.meta}>
              <Box sx={styles.column}>
                <Text sx={styles.price}>{price}</Text>
                <Text>$ US</Text>
              </Box>
              <button
                className="add-cart-button"
                onClick={() => {
                  return handleOpenModal();
                }}
              >
                <RiShoppingCartLine />
              </button>
            </Flex>
          )}
        </Flex>
      </Box>
    );
  }
};

export default ProductCardWithLink;
