const styles = {
  notFound: {
    "text-align": "center",
    paddingTop: ["25px", "50px"],
    paddingBottom: ["25px", "50px"],
    img: {
      marginBottom: 30,
    },
    span: {
      color: "cgTeal",
      fontSize: "32px",
      fontWeight: "600",
      fontFamily: "heading",
      position: "relative",
      marginBottom: [12, 32],
      img: {
        height: "72px",
        width: "72px",
        position: "absolute",
        zIndex: -1,
        right: "-5%",
        top: "-30%",
      },
    },
    h3: {
      marginTop: 32,
    },
  },
  button: {
    backgroundColor: "transparent",
    color: "#041C2C",
    "&:hover": {
      cursor: "pointer",
    },
  },
};

export default styles;
