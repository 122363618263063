import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Search from "./search";

const searchStaticQuery = graphql`
  query {
    allShopifyProduct {
      edges {
        node {
          id
          title
          tags
          handle
          createdAt
          shopifyId
          description
          variants {
            title
            sku
            id
            price
            image {
              id
              originalSrc
              gatsbyImageData(width: 200, layout: CONSTRAINED)
            }
            shopifyId
            availableForSale
          }
          images {
            id
            originalSrc
            gatsbyImageData(width: 200, layout: CONSTRAINED)
          }
        }
      }
    }
    allPrismicProduct {
      edges {
        node {
          data {
            details {
              text
            }
            story {
              text
            }
            ingredients {
              text
            }
            sku
            vendor {
              document {
                ... on PrismicVendor {
                  data {
                    vendor_name
                    description {
                      text
                    }
                  }
                }
              }
            }
            parent_product {
              document {
                ... on PrismicParentProduct {
                  data {
                    story {
                      text
                    }
                    details {
                      text
                    }
                    ingredients {
                      text
                    }
                    vendor {
                      document {
                        ... on PrismicVendor {
                          data {
                            vendor_name
                            description {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicParentProduct {
      edges {
        node {
          data {
            vendor {
              document {
                ... on PrismicVendor {
                  data {
                    vendor_name
                    description {
                      text
                    }
                  }
                }
              }
            }
            story {
              text
            }
            details {
              text
            }
            ingredients {
              text
            }
          }
        }
      }
    }
    allPrismicBlogContentModel {
      edges {
        node {
          data {
            post_title {
              text
            }
            release_date
            excerpt
            body {
              ... on PrismicBlogContentModelDataBodyDynamicContent {
                slice_type
                slice_label
                primary {
                  text_block {
                    text
                  }
                }
              }
              ... on PrismicBlogContentModelDataBodyProductCard {
                primary {
                  card_text {
                    text
                  }
                }
              }
              ... on PrismicBlogContentModelDataBodyCard {
                slice_type
                slice_label
                items {
                  card_text {
                    text
                  }
                }
              }
              ... on PrismicBlogContentModelDataBodyBlockquote {
                primary {
                  quote {
                    text
                  }
                }
              }
            }
            story_content_type {
              document {
                ... on PrismicStoryType {
                  data {
                    name
                  }
                }
              }
            }
            featured_image {
              fluid(maxWidth: 375) {
                ...GatsbyImgixFluid_noBase64
              }
            }
          }
          uid
          tags
        }
      }
    }
  }
`;

export default ({ setSearched }) => (
  <StaticQuery<GatsbyTypes.Query>
    query={`${searchStaticQuery}`}
    render={({
      allShopifyProduct,
      allPrismicProduct,
      allPrismicParentProduct,
      allPrismicBlogContentModel,
    }) => {
      return (
        <Search
          setSearched={setSearched}
          allShopifyProduct={allShopifyProduct}
          allStories={allPrismicBlogContentModel}
          allProducts={allPrismicProduct}
          allParent_products={allPrismicParentProduct}
        />
      );
    }}
  />
);
