const styles = {
  wrapper: {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: ["25px 18px 15px 30px", "20px 30px 15px"],
    button: {
      minWidth: "auto",
      minHeight: "auto",
      color: "primaryDarkGrey",
      outline: "none",
      transition: "0.4s all ease",
      border: "none",
      backgroundColor: "transparent",
      padding: 0,
      display: "none",
      svg: {
        height: 32,
        width: 32,
      },
      "&:hover": {
        color: "cgGold",
      },
      "@media only screen and (max-width: 1024px)": {
        display: "inline-flex",
      },
    },
  },
  text: {
    color: "menu",
    fontWeight: "600",
    fontSize: ["15px", "13px"],
    margin: 0,
  },
  links: {
    display: "flex",
    "flex-direction": "column",
    a: {
      paddingLeft: 4,
      paddingRight: 2,
      paddingBottom: 2,
      paddingTop: 2,
      textDecoration: "none",
      color: "inherit",
      fontSize: "20px",
    },
    ".active": {
      color: "cgGold",
    },
  },
  dropdown: {
    position: "relative" as "relative",
    display: "inline-block",
    "margin-top": "5px",
    svg: {
      width: "22px",
      height: "22px",
      transition: "all 0.3s ease",
      cursor: "pointer",
    },
    ".about-link": {
      cursor: "pointer",
      "margin-right": "10px",
    },
    ".about-wrap": {
      display: "flex",
      "align-items": "center",
    },
    ".dropdown-content": {
      padding: "8px 6px 11px",
      display: "none",
      position: "absolute" as "absolute",
      marginLeft: "9px",
      "background-color": "#fff",
      minWidth: "160px",
      boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.2)",
      zIndex: 1,
      a: {
        color: "subHeadingColor",
        padding: "8px 16px",
        "text-decoration": "none",
        display: "block",
        "font-size": "16px",
      },
      "&.open": {
        display: "block",
      },
    },
  },
};

export default styles;
