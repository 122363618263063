
const styles = {
  mailchimp: {
    paddingTop: "8px",
    position: "relative",
    justifyContent: "space-between",
    "@media screen and (max-width: 590px)": {
      "flex-direction": "column",
      alignItems: "center",
      paddingTop: "0",
    },
    ".label": {
      height: 0,
      overflow: "hidden",
      position: "absolute",
    },
    h3: {
      fontSize: ["32px", "35px", "42px"],
      lineHeight: "1.25em",
      color: "#fff",
      fontFamily: "'Lora', serif",
      fontWeight: "400",
      margin: "0 35px 0 0",
      width: "260px",
      "text-align": "left",
      "@media screen and (max-width: 590px)": {
        marginRight: "0",
        "text-align": "center",
        width: "100%",
      },
    },
    ".input": {
      height: "40px",
      border: "none",
      borderBottom: "2px solid #fff",
      marginLeft: "33px",
      width: "322px",
      "text-align": "center",
      padding: 0,
      font: "300 18px/32px 'Montserrat', Sans-serif",
      color: "#fff",
      backgroundColor: "transparent",
      outline: "none",
      "@media screen and (max-width: 890px)": {
        marginLeft: 0,
        width: "290px",
      },
      "@media screen and (max-width: 590px)": {
        margin: "0 0 10px",
        width: "100%",
        "text-align": "center",
      },

      "&::-webkit-input-placeholder": {
        font: "300 18px/32px 'Montserrat', Sans-serif",
        color: "#fff",
      },

      "&::-moz-placeholder": {
        font: "300 18px/32px 'Montserrat', Sans-serif",
        color: "#fff",
      },
    },
    span: {
      fontSize: ["22px", "28px"],
      fontFamily: "'Lora', serif",
      color: "#fff",
      lineHeight: "36px",
      "@media screen and (max-width: 890px)": {
        display: "block",
        "text-align": "right",
        margin: "0 0 12px",
        justifySelf: "flex-end",
      },
      "@media screen and (max-width: 590px)": {
        "text-align": "center",
      },
    },
  },
  buttonStyle: {
    "text-align": "right",
    justifySelf: "flex-end",
    paddingTop: "17px",
    paddingBottom: "69px",
    "@media screen and (max-width: 890px)": {
      paddingBottom: "50px",
      paddingTop: "10px",
    },
    "@media screen and (max-width: 590px)": {
      paddingBottom: "45px",
      paddingTop: "0",
    },
    button: {
      color: "#fff",
      padding: "8px 20px",
      backgroundColor: "cgMustard",
      border: "none",
      outline: "none",
      width: "322px",
      borderRadius: "3px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: "#f2a529",
      },
      "@media screen and (max-width: 890px)": {
        width: "290px",
      },
    },
  },
  message: {
    display: "inline-block",
    fontSize: "22px",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
};

export default styles;