/** @jsx jsx */
import { Box, jsx, Button } from "theme-ui";
import { FC, useState } from "react";
import styles from "./sidebar.style";
import { Link } from "gatsby";
import Icon from "../../../category-icon";

interface SidebarProps {
  onClose?: () => void;
  storiesMenu?: any;
}

const Sidebar: FC<SidebarProps> = ({ onClose }) => {
  const [open, setOpen] = useState(false);
  const [visitOpen, setVisitOpen] = useState(false);
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.header}>
        <Button variant="text" onClick={onClose}>
          <Icon name="close" />
        </Button>
      </Box>
      <div sx={styles.links}>
        <Link activeClassName="active" to="/farm">
          FARM
        </Link>
        <Link activeClassName="active" to="/cgfood">
          FOOD
        </Link>
        <Link activeClassName="active" to="/events">
          EVENTS
        </Link>
        <Link activeClassName="active" to="/shop">
          STORE
        </Link>
        <Link activeClassName="active" to="/cge">
          CGE
        </Link>
        <Box sx={styles.dropdown}>
          <a id="about" className="about-wrap" onClick={() => setOpen(!open)}>
            <span className="about-link">About</span>
            <Icon name="chevron-down" />
          </a>
          <div className={open ? "open dropdown-content" : "dropdown-content"}>
            <Link to="/mission">Mission</Link>
            <Link to="/team">Team</Link>
            <Link to="/about-campus">Campus</Link>
            <Link to="/incubator">Incubator</Link>
            <Link to="/media">Media</Link>
          </div>
        </Box>
        <Box sx={styles.dropdown}>
          <a
            id="about"
            className="about-wrap"
            onClick={() => setVisitOpen(!visitOpen)}
          >
            <span className="about-link">Visit</span>
            <Icon name="chevron-down" />
          </a>
          <div
            className={visitOpen ? "open dropdown-content" : "dropdown-content"}
          >
            <Link to="/plan-your-visit">Plan your visit</Link>
            <Link to="/venue">Book A Venue</Link>
            <Link to="/stone-dam">Stone Dam</Link>
            {/* <Link to="/farm-tours">Farm Tours</Link>
            <Link to="/lounge-nights">Lounge Nights</Link> */}
          </div>
        </Box>
        <Link activeClassName="active" to="/impressions">
          Gallery
        </Link>
        {/* <Box sx={styles.dropdown}>
          <a
            id="stories"
            className="about-wrap"
            activeClassName="active"
            onClick={() => setStoriesOpen(!storiesOpen)}
          >
            <span className="about-link">Gallery</span>
            <Icon name="chevron-down" />
          </a>
          <div
            className={
              storiesOpen ? "open dropdown-content" : "dropdown-content"
            }
          >
            {storiesMenu?.map((item) => {
              const uid = item?.story_category?.document?.uid;
              const name = item?.story_category?.document?.data?.name;
              return (
                <Link key={uid} to={`/${uid}`}>
                  {name}
                </Link>
              );
            })}
          </div>
        </Box> */}
      </div>
    </Box>
  );
};

export default Sidebar;
