/** @jsx jsx */
import { useEffect, useState } from "react";
import { Button, jsx } from "theme-ui";
import styles from "./back_top.style";
import { animateScroll as scroll } from "react-scroll";
import Icon from "../category-icon";

const options = {
  duration: 100,
};
const handleClick = () => {
  scroll.scrollToTop(options);
};

const BackTopButton: React.FC = ({}) => {
  const [toggleFadeIn, setToggleFadeIn] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      returnTop();
    };
  });
  const returnTop = () => {
    if (typeof window !== "undefined") {
      if (
        document.body.scrollTop > 150 ||
        document.documentElement.scrollTop > 150
      ) {
        setToggleFadeIn(true);
      } else {
        setToggleFadeIn(false);
      }
    }
  };
  return (
    <Button
      id="backtop"
      variant='none'
      className={toggleFadeIn ? "fadein" : "fadeout"}
      sx={styles.buttonStyles}
      onClick={handleClick}
    >
      <Icon name="arrow-up" />
    </Button>
  );
};

export default BackTopButton;
