import { Box, Text } from "theme-ui";
import styles from "./outline-button.style";

type Props = {
  text: string;
};

const OutlineButton: React.FC<Props> = ({ text }) => {
  return (
    <Box sx={styles.buttonStyle}>
      <Text as="span" sx={styles.innerButtonStyle}>
        {text}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#054752"
        >
          <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
        </svg>
      </Text>
    </Box>
  );
};

export default OutlineButton;
