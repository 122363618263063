import { keyframes } from "@emotion/react";

const popupDesktop = keyframes`
from {
  top: 70%;
}
to {
  top: 50%;
}
`;

const styles = {
  wrapper: {
    ".notificationBar": {
      opacity: "0",
      transition: "all 1s",
      bottom: "10%",
      height: "270px",
      padding: "16px 16px 48px",
      visibility: "hidden" as "hidden",
      backgroundColor: "white",
      zIndex: 9999,
      width: ["100%", "70%", "50%"],
      color: "black",
      "text-align": "center",
      display: "flex",
      "flex-direction": "column",
      alignContent: "center",
      justifyContent: "space-between",
      position: "fixed" as "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      "@media only screen and (max-width: 500px)": {
        height: "300px",
        padding: "16px 16px 32px",
        gridRowGap: "8px",
      },
      "@media only screen and (max-width: 375px)": {
        height: "330px",
      },
      span: {
        fontSize: [15, 19],
      },
      svg: {
        height: "24px",
        color: "#054752",
        marginTop: '8px',
        marginRight: "3px",
        "@media only screen and (max-width: 768px)": {
          height: "18px",
        }
      },
      ".close": {
        border: 'none',
        width: "25px",
        margin: "0 0 0 auto",
        svg: {
          height: "20px",
        }
      },
      ".text": {
        fontFamily: 'LORA',
        gridColumnGap: "10px",
        display: "flex",
        color: "#054752",
        fontWeight: 600,
        justifyContent: "center",
        alignItems: "flex-start",
        ".wrap": {
          fontSize: "24px",
          lineHeight: "30px",
          padding: 0,
          margin: 0,
          "@media only screen and (max-width: 768px)": {
            fontSize: "20px",
            lineHeight: "24px",
          },
        }
      },
      ".shop": {
        border: "1px solid #F69D0D",
        color: "#F69D0D",
        padding: '12px 60px',
        fontSize: "16px",
        width: "auto",
        fontWeight: 500,
        lineHeight: "20px",
        fontFamily: 'Lora',
        "@media only screen and (max-width: 1024px)": {
          padding: "16px",
        },
        "@media only screen and (max-width: 768px)":{
          fontSize: "16px",
          lineHeight: "18px",
          padding: "16px",
        },
        "@media only screen and (max-width: 500px)": {
          width: "100%",
        }
      },
      ".cart": {
        border: "1px solid #054752",
        color: "#054752",
        padding: '12px 60px',
        width: "auto",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "20px",
        fontFamily: 'Lora',
        "@media only screen and (max-width: 1024px)": {
          padding: "16px",
        },
        "@media only screen and (max-width: 768px)":{
          fontSize: "16px",
          lineHeight: "18px",
          padding: "16px",
        },
        "@media only screen and (max-width: 500px)": {
          width: "100%",
        }
      },
      ".buttons": {
        display: "flex",
        "flex-direction": "row",
        alignItems: "center",
        justifyContent: "center",
        gridColumnGap: "24px",
        "@media only screen and (max-width: 500px)": {
          "flex-wrap": "wrap",
          gridRowGap: "12px",
          marginTop: "16px",
        }
      },
    },
    ".animation": {
      opacity: "1",
      visibility: "visible" as "visible",
      zIndex: "500000",
      animation: `0.5s ${popupDesktop} cubic-bezier(0.215, 0.61, 0.355, 1)`,
    },
  }
}

export default styles