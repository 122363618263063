/** @jsx jsx */
import { Box, Text, Image, jsx } from "theme-ui";
import styles from "./button.style";
import goldShape from "../../images/gold_shape.png";

type Props = {
  text: string;
  onClick: any;
};

const ButtonCircle: React.FC<Props> = ({ text, onClick }) => {
  return (
    <Box sx={styles.buttonStyle}>
      <Text as="span" onClick={onClick}>
        {text}
        <Image src={goldShape} alt="empty cart" />
      </Text>
    </Box>
  );
};

export default ButtonCircle;
