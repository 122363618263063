/** @jsx jsx */
import React, { Fragment } from "react";
import { Flex, Box, Heading, Text, jsx } from "theme-ui";
import ProductCardWithLink from "../product-card/with-link";
import VariantSlider from "../product-slider/variant-slider";
import styles from "./product-grid.style";

type PropsType = {
  id?: string;
  related?: boolean;
  gridTitle?: string;
  products?: any;
  shopPage?: boolean;
  pairsWell?: boolean;
  handleVariantChange?: any;
  search?: boolean;
};

const ProductGrid: React.FC<PropsType> = ({
  id,
  gridTitle,
  products,
  shopPage,
  search,
  related,
  pairsWell,
  contentGrid,
  handleVariantChange,
}) => {
  const getPrice = (price: any) =>
  Intl.NumberFormat(undefined, {
    currency: "USD",
    minimumFractionDigits: 2,
    style: "currency",
  }).format(parseFloat(price || 0));

  const gridCards = () => {
    if (shopPage || pairsWell || search || contentGrid) {
      return products?.map((variant: any) => {
        
        const product = search ? variant?.node : variant;
        
        const { available, id, parentId, shopifyId, handle, price } = search
          ? variant?.node
          : variant;
          
        if (available) {
          return (
            <Fragment key={id}>
              <ProductCardWithLink
                size={
                  shopPage
                    ? "large"
                    : contentGrid
                    ? "med content-grid"
                    : "small"
                }
                product={product}
                parentId={parentId}
                shopifyId={shopifyId}
                path={`/product/${handle}`}
                price={getPrice(product.price)}
                shopPage={true}
                search={search}
                handleVariantChange={handleVariantChange}
                contentGrid={contentGrid}
              />
            </Fragment>
          );
        }
      });
    }
    if (related) {
      return (
        <VariantSlider
          data={products}
          size="small"
          related={true}
          handleVariantChange={handleVariantChange}
        />
      );
    }
  };

  let gridStyle;
  if (pairsWell) {
    gridStyle = "pairs-well";
  }
  if (shopPage) {
    gridStyle = "shop";
  }
  if (search) {
    gridStyle = "search";
  }
  if (contentGrid) {
    gridStyle = "content-grid";
  }
  const gridContent = gridCards();
  return (
    <Box id={id} sx={styles.wrapper} className={gridStyle}>
      {gridTitle && (
        <Flex sx={styles.header}>
          <Heading as="h3">{gridTitle}</Heading>
        </Flex>
      )}
      {products.length ? (
        <Box sx={styles.productGrid} className={gridStyle}>
          {gridContent}
        </Box>
      ) : null}
    </Box>
  );
};

export default ProductGrid;
