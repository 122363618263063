/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Wrapper, { styles } from "./carousel.style";
import Icon from "../category-icon";
import cx from "classnames";

type CarouselPropsType = {
  responsive: {};
  gutter?: string;
  showDots?: boolean;
  ssr?: boolean;
  renderDotsOutside?: boolean;
  visible?: boolean;
  hideButtons?: string;
  withOutSpace?: boolean;
};

type CustomButtonType = {
  next?: Function;
  previous?: Function;
  hideButtons?: string;
  withOutSpace?: boolean;
};

const ButtonGroup: React.FC<CustomButtonType> = ({
  next,
  previous,
  hideButtons,
  withOutSpace,
}) => {
  return (
    <Box
      sx={styles.carouselNav}
      className={`carousel-button-group ${hideButtons}`}
    >
      <Box
        as="button"
        sx={styles.prev}
        className={cx("", {
          "with-out-space": withOutSpace,
        })}
        onClick={() => previous?.()}
      >
        <Icon name="chevron-back" />
      </Box>
      <Box
        as="button"
        sx={styles.next}
        className={cx("", {
          "with-out-space": withOutSpace,
        })}
        onClick={() => next?.()}
      >
        <Icon name="chevron-forward" />
      </Box>
    </Box>
  );
};

let startX = 0;

const swipeAction = (event: any) => {
  const { type } = event;
  const { screenX } = event.changedTouches[0];
  const threshold = 20;

  if (type === "touchstart") {
    startX = screenX;
  } else if (type === "touchmove") {
    if (screenX > startX + threshold || screenX < startX - threshold) {
      // moved more than 20px left or right
      document.body.classList.add("prevent-scroll");
    }
  } else if (type === "touchend") {
    document.body.classList.remove("prevent-scroll");
    startX = 0;
  }
};

const Carousel: React.FunctionComponent<CarouselPropsType> = ({
  ssr,
  gutter,
  children,
  responsive,
  showDots,
  renderDotsOutside,
  visible,
  hideButtons,
  withOutSpace,
}) => {
  /* console.log(children); */
  return (
    <Wrapper
      gutter={gutter}
      className="custom-slider"
      onTouchEnd={swipeAction}
      onTouchMove={swipeAction}
      onTouchStart={swipeAction}
      withOutSpace={withOutSpace}
    >
      <MultiCarousel
        ssr={ssr}
        infinite={true}
        arrows={false}
        swipeable={true}
        responsive={responsive}
        showDots={showDots}
        partialVisible={visible}
        renderDotsOutside={renderDotsOutside}
        renderButtonGroupOutside={true}
        customButtonGroup={
          <ButtonGroup hideButtons={hideButtons} withOutSpace={withOutSpace} />
        }
        dotListClass="custom-dot-list-style"
      >
        {children}
      </MultiCarousel>
    </Wrapper>
  );
};

export default Carousel;
