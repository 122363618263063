/** @jsx jsx */
import React from "react";
import { Flex, Box, jsx } from "theme-ui";
import styles from "./mailchimp-modal.styles";

const MailchimpModal: React.FunctionComponent<{}> = ({ handleEmailChange, setOpen }) => {
  
  const handleClick = (e) => {
    e.preventDefault();
    localStorage.setItem('subscribed', true);
    setOpen(!open)
  }
  return (
    <Flex sx={styles.mailchimp}>
      <h3>
        <span>{`Get updates & save`}</span>
      </h3>
      <h4 className="text">Subscribe to the Common Ground newsletter and get 15% off your next order.</h4>
      <div className="subscribe">
        <span className="email-title">EMAIL</span>
        <label htmlFor="email" className="label">
          Email
        </label>
        <input
          className="input"
          //placeholder="Your Email"
          name="email"
          type="email"
          required
          id="email"
          onChange={handleEmailChange}
        />
        <div className="button-group"> 
          <Box sx={styles.buttonStyle}>
            <button
              sx={{ variant: "buttons.submit" }}
              type="submit"
              className="mail-form-button"
            >
              SUBMIT
            </button>
          </Box>
          <button
            type="button"
            className="no-thanks mobile"
            onClick={handleClick}
          >
            No thanks
          </button>
        </div>
      </div>
      <button
        type="button"
        className="no-thanks desktop"
        onClick={handleClick}
      >
        No thanks
      </button>
      <p className="note">Your discount code will be sent by email</p>
    </Flex>
  )
};

export default MailchimpModal