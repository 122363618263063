const styles = {
  wrapper: {
    display: "inline-flex",
    alignItems: "center",
    width: "auto",
    a: {
      display: "flex",
      transition: "all 0.2s ease",
      "@media only screen and (max-width: 1080px)": {
        justifyContent: "center",
      },
    },
    img: {
      width: "auto",
      "@media only screen and (max-width: 1080px)": {
        height: "80px",
        marginLeft: "1rem",
        marginRight: "1rem",
      },
      height: "80px",
      "@media only screen and (max-width: 450px)": {
        height: "50px",
        marginLeft: ".5rem",
        marginRight: ".5rem",
      },
    },
  },
  logo: {
    position: "relative",
    top: "0",
  },
};

export default styles;
