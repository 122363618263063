const styles = {
  wrapper: {
    paddingY: 20,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "muted",
    pointerEvent: "all",
    "&.disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
      pointerEvents: "none",
    },
    "&:hover": {
      button: {
        opacity: 1,
        visibility: "visible",
      },
    },
  },
  titlePrice: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "space-between",
  },
  imageWrapper: {
    width: [90, 105],
    height: [90, 105],
    position: "relative",
    overflow: "hidden",
    borderRadius: 6,
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    button: {
      opacity: 0,
      visibility: "hidden",
      position: "absolute",
      width: "100%",
      top: 0,
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.25)",
      color: "white",
      transition: "all 0.3s ease",
      "&:hover": {
        cursor: "pointer",
        svg: {
          fill: "#D79A2B"
        }
      },
      "&:focus": {
        cursor: "pointer",
        svg: {
          fill: "#D79A2B"
        }
      },
      svg: {
        fill: "white",
        height: "3em",
        width: "3em",
        transition: "all 0.3s ease",
      },
    },
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: ["calc(100% - 90px)", "calc(100% - 105px)"],
    paddingLeft: 15,
    "@media only screen and (max-width: 767px)": {
      "flex-wrap": "wrap",
      "flex-direction": "column",
      justifyContent: "flex-start",
    },
  },
  title: {
    fontFamily: "heading",
    fontSize: "15px",
    margin: "0px 0px 10px",
    color: "subHeadingColor",
    marginBottom: 10,
  },
  variantTitle: {
    fontFamily: "Montserrat",
    fontSize: '14px',
    color: "#979797",
    marginBottom: 10,
  },
  unitPrice: {
    color: "subHeadingColor",
    fontFamily: "heading",
    fontSize: "13px",
  },
  totalPrice: {
    color: "Heading",
    fontWeight: "700",
    fontFamily: "Heading",
    fontSize: 1,
  },
  counterWrapper: {
    display: "flex",
    "flex-direction": "column-reverse",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: [20, 0],
    "@media only screen and (max-width: 767px)": {
      "flex-direction": "row",
      alignItems: "center",
    },
  },
  cartCounter: {
    display: "flex",
    alignItems: "center",
    minWidth: [100, 106],
    minHeight: [32, 36],
    borderRadius: 6,
    overflow: "hidden",
    backgroundColor: "transparent",
    ".quantity": {
      color: "subHeadingColor",
    },
    div: {
      width: [42, 48],
      color: "primaryDarkGrey",
      "text-align": "center",
      fontSize: 0,
      fontWeight: "700",
    },
    button: {
      backgroundColor: "transparent",
      padding: 0,
      minWidth: 32,
      border: "none",
      outline: "none",
      minHeight: [32, 36],
      svg: {
        width: 20,
        height: 20,
        marginLeft: 0,
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
};

export default styles;
