import React, { useContext } from "react";
import { Flex, Box, Text, Button } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import styles from "./line-item.style";
import { LocalCartContext } from "../../../provider/local-cart-provider";
import Icon from "../../category-icon";

const LineItem = (props: any) => {
  const { item } = props;
  const lineItemQuantity = item ? item.quantity : 0;
  const { update } = useContext(LocalCartContext);
  const handleQuantityChange = (quantity: number) => {
    update(item.variantId, quantity);
  };
  const handleRemove = () => {
    update(item.variantId, 0);
  };
  const getPrice = (price: any) =>
    Intl.NumberFormat(undefined, {
      currency: "USD",
      minimumFractionDigits: 2,
      style: "currency",
    }).format(parseFloat(price ? price : 0));

  return (
    <>
      {lineItemQuantity > 0 ? (
        <Flex sx={styles.wrapper}>
          <Box sx={styles.imageWrapper}>
            {item.thumbnail ? (
              <GatsbyImage image={item.thumbnail} alt={item.title} />
            ) : null}
            {/* {variantImage} */}
            <Button variant="text" title="Delete" onClick={handleRemove}>
              <Icon name="close-circle" />
            </Button>
          </Box>
          <Box sx={styles.contentWrapper}>
            <Box sx={styles.titlePrice}>
              <Text sx={styles.title}>{item.title}</Text>
              {item.variantTitle !== "Default Title" && <Text sx={styles.variantTitle}>{item.variantTitle}</Text>}
              <Text sx={styles.unitPrice}>
                Unit Price {getPrice(item.price)}
              </Text>
            </Box>
            <Box sx={styles.counterWrapper}>
              <Box
                sx={{
                  ...styles.cartCounter,
                }}
              >
                <Button
                  title="Decrement"
                  onClick={() => handleQuantityChange(item.quantity - 1)}
                >
                  <Icon name="remove" />
                </Button>
                <div className="quantity">{item.quantity}</div>
                <Button
                  title="Increment"
                  onClick={() => handleQuantityChange(item.quantity + 1)}
                >
                  <Icon name="add" />
                </Button>
              </Box>
              <Text sx={styles.totalPrice}>
                {getPrice((item.price * item.quantity).toFixed(2))}
              </Text>
            </Box>
          </Box>
        </Flex>
      ) : null}
    </>
  );
};

export default LineItem;
