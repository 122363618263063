const styles = {
  wrapper: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    height: "100%",
    padding: 30,
    position: "relative",
    "@media only screen and (max-width: 480px)": {
      padding: "20px 30px 30px",
    },
    button: {
      backgroundColor: "transparent",
      color: "#535F61",
      transition: "0.4s all ease",
      "&:hover": {
        cursor: "pointer",
        color: "cgGold",
      },
    },
  },
  shippingInfo: {
    padding: ['12px 0 0', '12px 5px 0'],
    "text-align": 'center',
    span: {
      fontWeight: 600,
      display: "inline",
      //fontSize: '16px',
      //paddingBottom: '5px'
    },
    p: {
      padding: ['0', '5px 0']
    },
    '.italic': {
      fontStyle: 'italic',
    }
  },
  title: {
    color: "headingColor",
    fontWeight: "600",
    fontFamily: "Heading",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 750,
    marginX: "auto",
    button: {
      marginRight: -18,
      outline: "none",
      border: "none",
      svg: {
        height: 27,
        width: 27,
      },
    },
  },
  totalPrice: {
    width: "calc(100% + 60px)",
    padding: "15px 30px",
    marginTop: 30,
    fontFamily: "Heading",
    color: "headingColor",
    marginLeft: "-30px",
    backgroundColor: "background",
    position: "relative",
    "@media only screen and (max-width: 480px)": {
      marginTop: 18,
    },
    "&::after": {
      content: '""',
      position: "absolute",
      right: 0,
      bottom: -30,
      width: "100%",
      height: "30px",
      zIndex: 1,
      pointerEvents: "none",
      "@media only screen and (max-width: 480px)": {
        display: "none",
      },
    },
    ">div": {
      width: "100%",
      maxWidth: 750,
      marginX: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    h4: {
      fontFamily: "body",
      fontSize: 1,
      color: "primary",
    },
    div: {
      fontSize: 1,
    },
  },
  cartItems: {
    width: "100%",
    maxWidth: 780,
    marginX: "auto",
    overflow: "hidden",
    flexGrow: "1",
    height: "calc(100% - 240px)",
    "@media only screen and (max-width: 480px)": {
      height: "calc(100% - 206px)",
    },
    "> div": {
      paddingRight: [32, 46],
      marginRight: -30,
      paddingLeft: [0, 15],
      height: "100%",
      overflow: "auto",
      overflowX: "hidden",
      overflowY: "scroll"
    },
  },
};

export default styles;
