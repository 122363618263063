const styles = {
  wrapper: {
    width: "80%",
    paddingTop: "25px",
    backgroundColor: "white",
    ":last-child": {
      marginBottom: 0,
    },
    "&.content-grid": {
      paddingTop: 0,
    },
    "&.pairs-well": {
      borderTop: "solid #AE1485 3px",
      borderBottom: "solid #AE1485 3px",
      paddingBottom: "25px",
    },
    h3: {
      color: "cgTeal",
      fontSize: ["25px", "30px", "39px"],
      lineHeight: "36px",
      fontFamily: "heading",
      fontWeight: "300",
      marginBottom: ["10px", "30px"],
    },
  },
  header: {
    width: "100%",
    alignItems: "center",
    justifyContent: ["space-between", "flex-start"],
    marginBottom: [20, 30],
    button: {
      textDecoration: "underline",
      marginLeft: [0, "5px"],
    },
  },
  productGrid: {
    display: "grid",
    gridColumnGap: "28px",
    gridRowGap: "45px",
    "&.shop": {
      gridTemplateColumns: "1fr",
      "@media screen and (min-width:715px)": {
        gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
        gridRowGap: "50px",
        marginBottom: "50px",
      },
    },
    "&.pairs-well": {
      gridTemplateColumns: "1fr",
    },
    "&.content-grid": {
      gridTemplateColumns: "1fr",
      gridColumnGap: "20px",
      gridRowGap: "0",
      "@media screen and (min-width: 560px)": {
        gridTemplateColumns: "1fr 1fr",
      },
    },
    "&.search": {
      margin: 0,
      gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
    },
  },
};

export default styles;
