/** @jsx jsx */
import React, { FC, useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { Text, Flex, Box, jsx } from "theme-ui";
import styles from "./mailchimp.styles";
import styled from "@emotion/styled";
import MailchimpFooter from "./mailchimp-footer";
import MailchimpModal from "./mailchimp-modal";

interface MailChimpFormProps {
  modal: boolean;
  setOpen: (open: boolean) => void;
}

const MailChimpForm: FC<MailChimpFormProps> = ({ modal, setOpen }) => {
  const [email, setEmail] = useState("");
  const [result, setResult] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addToMailchimp(email)
      .then((data: any) => {
        setResult(data.result);
        setMessage(data.msg);
      })
      .catch((error: Error) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      });
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };
  if (result === "success") {
    localStorage.setItem("subscribed", "true");
    return (
      <Text as="p" sx={styles.message}>
        Success! Thank you for subscribing.
      </Text>
    );
  }
  if (result === "error") {
    if (message.includes("not valid")) {
      return (
        <Text as="p" sx={styles.message}>
          Please enter a valid email address.
        </Text>
      );
    }
    if (message.includes("already subscribed")) {
      localStorage.setItem("subscribed", "true");
      return (
        <Text as="p" sx={styles.message}>
          You've already subscribed!
        </Text>
      );
    }
    return (
      <Text as="p" sx={styles.message}>
        There was an error with your subscription, please try again.
      </Text>
    );
  } else {
    return (
      <Box as="form" name="Newsletter" onSubmit={handleSubmit}>
        {modal ? (
          <MailchimpModal
            handleEmailChange={handleEmailChange}
            setOpen={setOpen}
          />
        ) : (
          <MailchimpFooter handleEmailChange={handleEmailChange} />
        )}
      </Box>
    );
  }
};

export default MailChimpForm;
