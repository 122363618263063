import styled from '@emotion/styled';

export const StyledGiftNote = styled.div`
  padding-top: 15px;
  .form {
    width: 100%;
    margin: 10px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
`
export const NoteModal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #535F61;
  background-color: rgba(150, 150, 150, .3);
  transition: all .2s ease;
  visibility: ${({ open }) => open ? "visible" : "hidden"};
  opacity: ${({ open }) => open ? "1" : "0"};
  h3 {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 1.5em;
  }
  .note-box {
    background-color: #fff;
    width: 75%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 12px 25px 20px;
    border-radius: 10px;
    border: 1px solid #F3A529;
  }
  .input {
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    min-height: 150px;
    font-family: Montserrat;
    font-size: .9rem;
    padding: 10px;
    color: #878787;
    :focus-visible {
      outline: none;
    }
  }
  .buttons {
    display: flex;
  }
  .button {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 12px;
    margin-top: 25px;
    border-radius: 2px;
    border: 1px solid #D0D0D0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #D0D0D0;
    &.right {
      margin-right: 15px;
    } 
    &.add {
      background-color: #F3A529;
      border: none;
      span {
        color: #fff;
        padding-left: 10px;
      }
    }
    &.popup {
      margin-bottom: 24px;
      padding: 6px 12px;
      background-color: #F3A529;
      border: none;
      span {
        color: #fff;
      }
    }
    &.save:hover {
      color: #D0D0D0;
    }
  }
  .confirmation {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      margin-top: 30px;
      margin-bottom: 16px;
    }
  }
  .inner-wrap {
    max-width: 270px;
    .intro {
      margin: 20px auto 40px;
      max-width: 220px;
    }
  }
`

export const Checkbox = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
  .add {
    margin-right: 20px;
  }
  button {
    padding: 4px 12px;
    background-color: #F3A529;
    border-radius: 2px;
    border: none;
    display: flex;
    align-items: center;
    span {
      color: #fff;
      padding-left: 10px;
      font-size: 12px;
      font-weight: 600;
    }
    &.delete {
      background-color: #fff;
      border: 1px solid #D0D0D0;
      margin-left: 10px;
      span {
        color: #D0D0D0;
      }
    }
  }
`