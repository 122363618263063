/** @jsx jsx */
import React, { useState, useContext } from "react";
import { Box, jsx } from "theme-ui";
import { Waypoint } from "react-waypoint";
import { animated, useSpring } from "react-spring";
import Container from "../../container/container";
import Header from "./header/header-modern";
import Footer from "./footer/footer";
import styles from "./primary.style";
import BackTopButton from "../../back_top/back_top";
import { LocalCartContext } from "../../../provider/local-cart-provider";
//import ProductModal from "../../product-modal/product-modal"
import { ProductContext } from "../../../provider/product-provider";
import ProductAddedModal from "../../product-added-modal/product-added-modal";

const PrimaryLayout: React.FunctionComponent<{
  fluid?: boolean;
  showNoticeBar?: boolean;
  sidebar?: boolean;
  bgColor?: string;
  homeLink?: string;
  pathPrefix?: string;
  shop?: boolean;
}> = ({ fluid, showNoticeBar, homeLink, pathPrefix, children, shop }) => {
  const [cartBar, setCartBar] = useState(false);
  const cartBtnProps = useSpring({
    config: { mass: 1, tension: 500, friction: 48 },
    bottom: cartBar ? 87 : 30,
  });
  const [searched, setSearched] = useState(false);
  const { setCartVisible } = useContext(LocalCartContext);
  const {
    toggleModal,
    setToggleModal,
    currentVariant,
  }: {
    toggleModal: boolean;
    setToggleModal: React.Dispatch<React.SetStateAction<boolean>>;
    currentVariant: any;
  } = useContext(ProductContext) as {
    toggleModal: boolean;
    setToggleModal: React.Dispatch<React.SetStateAction<boolean>>;
    currentVariant: any;
  };

  const handleCart = () => {
    setToggleModal(false);
    setCartVisible(true);
  };
  return (
    <Box
      as="main"
      sx={searched ? styles.searchedMain : styles.main}
      id={shop ? "shop-main" : "main"}
    >
      <Header
        fluid={fluid}
        setSearched={setSearched}
        homeLink={homeLink}
        pathPrefix={pathPrefix}
        showNoticeBar={showNoticeBar}
      />
      <Container
        sx={{ maxWidth: "100%", padding: 0, width: "100%" }}
        fluid={fluid}
      >
        <BackTopButton />
        {children}
      </Container>
      <Waypoint
        onEnter={() => setCartBar(true)}
        onLeave={() => setCartBar(false)}
      />
      <animated.div
        style={{ ...cartBtnProps, position: "relative", zIndex: 9999 }}
      />
      <Footer fluid={fluid} />
      {toggleModal && <Box sx={styles.modalBackground} />}
      <ProductAddedModal
        title={
          currentVariant.variants?.length > 1 && !currentVariant.isVariant
            ? `${currentVariant.title} ${currentVariant.variants[0].title}`
            : currentVariant.variants?.length > 1
            ? `${currentVariant.title} ${currentVariant.subtitle}`
            : currentVariant.title
        }
        handleCart={handleCart}
        togglePopup={toggleModal}
        setTogglePopup={setToggleModal}
      />
      {/* toggleModal && <ProductModal toggleModal={toggleModal} setToggleModal={setToggleModal} /> */}
    </Box>
  );
};

export default PrimaryLayout;
