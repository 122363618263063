/** @jsx jsx */
import { jsx, Box } from "theme-ui";

const Wrapper = ({ ...props }) => (
  <Box
    {...props}
    sx={{
      li: {
        "@media only screen and (max-width: 600px)": {
          minWidth: "280px !important",
          width: "100%",
        },
      },
      ".med": {
        marginRight: "20px !important",
        "@media only screen and (min-width: 1250px)": {
          margin: "0 auto !important",
        },
      },
      position: "relative",
      ".react-multi-carousel-list": {
        /* padding: "0",
        margin: "0",
        justifyContent: "center", */
        "@media only screen and (max-width: 590px)": {
          /* left: `-4%`, */
          marginX: `0`,
        },
      },
      ".react-multi-carousel-item": {
        paddingX: props.gutter,
        display: "flex",
        margin: "0 15px",
        "@media only screen and (max-width: 600px)": {
          margin: "0 !important",
          left: `1%`,
          /* border: "1px solid #EAEAEA", */
        },
      },
      ".react-multi-carousel-dot-list": {
        marginTop: "25px",
        "@media only screen and (max-width: 480px)": {
          marginTop: "10px",
        },
        ".react-multi-carousel-dot": {
          lineHeight: "1",
          button: {
            width: "8px",
            height: "8px",
            border: "0",
            borderRadius: "8px",
            marginRight: "8px",
            backgroundColor: "carouselDot",
            transition: "width 0.2s ease",
          },
          "&.react-multi-carousel-dot--active": {
            button: {
              width: "20px",
              backgroundColor: "carouselActiveDot",
            },
          },
        },
      },
    }}
  />
);

export default Wrapper;

export const styles = {
  carouselNav: {
    "&.hide-over": {
      display: "none",
    },
    "&.hide-under": {
      "@media screen and (max-width: 1349px)": {
        display: "none",
      },
    },
    button: {
      alignItems: "center",
      backgroundColor: "white",
      borderWidth: "1px",
      borderColor: "cgTeal",
      borderStyle: "solid",
      borderRadius: "50%",
      cursor: "pointer",
      display: "flex",
      height: 40,
      justifyContent: "center",
      padding: 0,
      position: "absolute" as "absolute",
      top: "calc(50% - 20px)",
      width: 40,
      fontSize: "18px",
      svg: {
        height: "22px",
        width: "22px",
        color: "#212B36",
      },
      ":focus": {
        outline: "none",
      },
      "@media screen and (max-width: 375px)": {
        width: "32px",
        height: "32px",
        top: "calc(50% - 16px)",
        fontSize: "16px",
      },
    },
  },
  prev: {
    left: "-60px",
    "@media screen and (max-width: 375px)": {
      left: "-16px",
    },
    "&.with-out-space": {
      left: "20px",
      backgroundColor: "transparent",
      color: "white",
      borderColor: "white",
      svg: {
        color: "white",
      },
      "@media screen and (max-width: 375px)": {
        left: "20px",
      },
    },
  },
  next: {
    right: "-60px",
    "@media screen and (max-width: 375px)": {
      right: "-16px",
    },
    "&.with-out-space": {
      right: "20px",
      backgroundColor: "transparent",
      color: "white",
      borderColor: "white",
      svg: {
        color: "white",
      },
      "@media screen and (max-width: 375px)": {
        right: "20px",
      },
    },
  },
};
