/** @jsx jsx */
import { useContext, useState } from "react";
import { Box, Flex, Heading, Text, Button, Image, jsx } from "theme-ui";
import { CartContext } from "../../provider/cart-provider";
import styles from "./cart.style";
import { LocalCartContext } from "../../provider/local-cart-provider";
import ButtonCircle from "../button/button";
import Icon from "../category-icon";
import GiftNote from "./gift-note";
import Popup from "./popup";

const Cart: React.FunctionComponent<{ onClick?: () => void }> = ({
  onClick,
  children,
}: any) => {
  const { products, totalPrice, currency, clearCart } = useContext(
    LocalCartContext
  );
  const { store, noteText, setNoteText, addToCartAndCheckout } = useContext(CartContext);
  const { checkout, updating } = store;
  const [showPopup, setShowPopup] = useState(false)
  const handleCheckout = () => {
    if (noteText !== '') {
      setShowPopup(true)
    } else {
      addToCartAndCheckout();
      onClick();
    }
  };

  const getPrice = (price: any) =>
    Intl.NumberFormat(undefined, {
      currency: "USD",
      minimumFractionDigits: 2,
      style: "currency",
    }).format(parseFloat(price ? price : 0));
  return (
    <Box sx={styles.wrapper}>
      <Heading as="h2" sx={styles.title}>
        Cart
        <Button title="Close" variant="text" onClick={onClick}>
        <Icon name="close" />
        </Button>
      </Heading>

      <Flex sx={styles.totalPrice}>
        <Box>
          <Text>Total Amount:</Text>
          <Heading as="h4">{getPrice(totalPrice)}</Heading>
        </Box>
      </Flex>
      <Box sx={styles.cartItems}>{children}</Box>
      { products.length > 0 && <GiftNote noteText={noteText} setNoteText={setNoteText}/> }
      <Box sx={styles.shippingInfo}>
        <Text as="p" className="italic"><span>Free shipping</span> for orders over $150</Text>
        <Text as="p" className="italic">Flat rate shipping anywhere in the US: $12</Text>
        <Text as="p" className="italic">We don't offer international shipping at this time</Text>
      </Box>
      {products.length === 0 ? (
        <ButtonCircle text="Back to shop" onClick={onClick} />
      ) : (
        <ButtonCircle
          text={updating ? "Loading..." : "Proceed to checkout"}
          onClick={handleCheckout}
        />
      )}
      {showPopup &&
        <Popup
          noteText={noteText}
          checkout={addToCartAndCheckout}
          closeCart={onClick}
        />
      }
    </Box>
  );
};

export default Cart;
