import styled from "@emotion/styled";

export const StyledInput = styled.section`
  .label {
    height: 0;
    overflow: hidden;
    position: absolute;
  }
  .container {
    position: relative;
  }
  input.search_bar {
    position: relative;
    border: none;
    outline: none;
    width: 0px;
    border-radius: 55px;
    margin: 0 auto;
    font-size: 1em;
    display: block;
    margin: 0 0 0 auto;
    user-events: none;
    user-select: none;
    color: #0d2840;
    padding: 12.5px 0px 12.5px 0px;
    transition: width 0.3s cubic-bezier(0, 0, 0.5, 1.5);
    background: #e6e6e6 no-repeat center center;
  }
  input.search_bar:focus {
    padding-right: 35px;
    padding-left: 17.5px;
    width: 100%;
    background-position: calc(100% - 10px) center;
  }

  svg {
    position: absolute;
    top: 2px;
    right: 0;
    margin: 12px 10px 0 10px;
    height: 22px;
    width: 22px;
    transition: 0.4s all ease;
  }

  svg:hover {
    cursor: pointer;
    color: #d79a2b;
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  @media screen and (max-width: 890px) {
    input.search_bar {
      width: 100%;
      background-position: calc(100% - 10px) center;
      background-color: transparent;
      color: black;
      padding: 12.5px 35px 12.5px 17.5px;
      border-radius: 0;
      font-size: 16px;
    }

    svg {
      margin: 10px;
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 590px) {
    .container {
      display: none;
    }
  }
`;
