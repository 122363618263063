const styles = {
  buttonStyle: {
    "text-align": "center",
    bottom: "20px",
    border: "1px solid",
    borderColor: "cgTeal",
    transition: ".3s all",
    color: "cgTeal",
    display: "inline-block",
    "&:hover, &:focus": {
      borderColor: "cgGold",
      color: "cgGold",
    },
  },
  innerButtonStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "15px",
    "text-transform": "uppercase",
    padding: "15px 30px",
    gap: "10px",
    "@media only screen and (min-width: 500px)": {
      fontSize: "16px",
    },
  },
};

export default styles;
