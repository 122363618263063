/** @jsx jsx */
import React from "react";
import { Text, Flex, Box, jsx } from "theme-ui";
import styles from "./mailchimp.styles";

const MailchimpFooter: React.FunctionComponent<{}> = ({ handleEmailChange }) => {
  return (
    <Flex sx={styles.mailchimp}>
      <h3>Ready to learn more?</h3>
      <div className="subscribe">
        <span>Follow us!</span>
        <label htmlFor="email" className="label">
          Email
        </label>
        <input
          className="input"
          placeholder="Your Email"
          name="email"
          type="email"
          required
          id="email"
          onChange={handleEmailChange}
        />
        <Box sx={styles.buttonStyle}>
          <button
            sx={{ variant: "buttons.submit" }}
            type="submit"
            className="mail-form-button"
          >
            Join
          </button>
        </Box>
      </div>
    </Flex>
  )
};

export default MailchimpFooter;