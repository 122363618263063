/** @jsx jsx */
import { FC } from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { Box, Flex, Text, Heading, jsx } from "theme-ui";
import styles from "./blog-post-preview.style";
import kebabCase from "lodash/kebabCase";
import cx from "classnames";

interface BlogPostPreviewProps {
  content: any;
  previewType?: string;
  previewStyle?: string;
  aboutPrev?: boolean;
  full?: boolean;
  hideCat?: boolean;
  archive?: boolean;
  aboutImg?: any;
  singleRow?: boolean;
  short?: boolean;
  deletePadding?: boolean;
}

const BlogPostPreview: FC<BlogPostPreviewProps> = ({
  content,
  previewType,
  aboutPrev,
  full,
  hideCat,
  archive,
  aboutImg,
  singleRow,
  short,
  deletePadding,
}) => {
  const post = content?.data;
  const title = post?.post_title.text;
  const excerpt = post?.excerpt;
  const image = post?.featured_image.fluid;
  const link = content?.uid;
  const category = post?.story_content_type
    ? post.story_content_type.document?.data.name
    : "uncategorized";
  const categorySlug = kebabCase(category);

  const stories = () => (
    <Box
      sx={styles.wrapper}
      className={
        !archive
          ? "stories"
          : short
          ? "stories archive short"
          : "stories archive"
      }
    >
      <Link to={`/${categorySlug}`}>
        <Text
          as="p"
          sx={styles.catHeading}
          className={hideCat ? "padded hidden" : "padded"}
        >
          {category}
        </Text>
      </Link>
      <Link to={`/${link}`}>
        <Box sx={styles.imageWrap} className={short ? "xs" : "squared"}>
          <Img fluid={image} />
        </Box>
        <Heading
          as="h2"
          sx={styles.title}
          className={short ? "spaced md" : "md"}
        >
          {title}
        </Heading>
        <Text as="p" sx={styles.description}>
          {excerpt}
        </Text>
        <Box sx={styles.btnMore}>
          <Text as="span" sx={styles.readMore} className="stories">
            Read More
          </Text>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#054752"
            className="svg-button"
          >
            <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
          </svg>
        </Box>
      </Link>
    </Box>
  );
  const relatedPosts = () => (
    <Box sx={styles.wrapper} className="related">
      <Link to={`/${link}`}>
        <Box
          sx={styles.imageWrap}
          className={cx("xs", {
            delete: deletePadding,
          })}
        >
          <Img fluid={image} />
        </Box>
        <Box
          className={cx("padded-wrap", {
            delete: deletePadding,
          })}
        >
          <Heading as="h2" sx={styles.title} className="sm">
            {title}
          </Heading>
        </Box>
        <Box
          className={cx("padded-wrap", {
            delete: deletePadding,
          })}
        >
          {/* {excerpt && (
            <Text as="p" sx={styles.description} className="related">
              {excerpt}
            </Text>
          )} */}
          <Box sx={styles.btnMore} className="related">
            <Text as="span" sx={styles.readMore} className="related">
              Read More
            </Text>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#054752"
              className="svg-button"
            >
              <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
            </svg>
          </Box>
        </Box>
      </Link>
    </Box>
  );
  const relatedPostsWide = () => (
    <Flex sx={styles.wrapper} className="padded home-big">
      <Box sx={styles.imageWrap} className="large related-wide">
        <Link to={`/${categorySlug}`}>
          <Text as="p" sx={styles.catHeading} className="md">
            {category}
          </Text>
        </Link>
        <Link to={`/${link}`} aria-label={title}>
          <Img fluid={image} />
        </Link>
        <div>
          <Link to={`/${link}`}>
            <Heading as="h2" sx={styles.title} className="md">
              {title}
            </Heading>
            <Text as="p" sx={styles.excerpt}>
              {excerpt}
            </Text>
          </Link>
        </div>
        <Link to={`/${link}`} aria-label={title}>
          <Box sx={styles.btnMore}>
            <Text as="span" sx={styles.readMore} className="stories">
              Read More
            </Text>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#054752"
              className="svg-button"
            >
              <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
            </svg>
          </Box>
        </Link>
      </Box>
      {/* <Flex className="info-wrap related"></Flex> */}
    </Flex>
  );
  const homeBig = () => (
    <Flex sx={styles.wrapper} className="home-big">
      <Box sx={styles.imageWrap} className="wide">
        <Link to={`/${link}`} aria-label={title}>
          <Img fluid={aboutPrev ? aboutImg : image} />
        </Link>
      </Box>
      <Flex className={aboutPrev ? "info-wrap reverse home" : "info-wrap home"}>
        <div>
          <Link to={`/${categorySlug}`}>
            <Text as="p" sx={styles.catHeading} className="lg">
              {category}
            </Text>
          </Link>
          <Link to={`/${link}`}>
            <Heading as="h2" sx={styles.title} className="xl">
              {title}
            </Heading>
          </Link>
        </div>
        <Link to={`/${link}`} aria-label={title}>
          <Box sx={styles.btnMore}>
            <Text as="span" sx={styles.readMore} className="stories">
              Read More
            </Text>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#054752"
              className="svg-button"
            >
              <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
            </svg>
          </Box>
        </Link>
      </Flex>
    </Flex>
  );
  const shop = () => (
    <Box
      sx={styles.overlayWrap}
      className={singleRow ? "homeSm singleRow" : "homeSm gallery"}
    >
      <Link to={`/${link}`}>
        <Box sx={styles.imageWrap} className="sm">
          <Img fluid={image} />
        </Box>
        <Box sx={styles.overlayText}>
          <Heading as="h2" sx={styles.title} className="md padded">
            {title}
          </Heading>
          <Text as="span" sx={styles.readMore}>
            Read More
          </Text>
        </Box>
      </Link>
    </Box>
  );
  const about = () => (
    <Box sx={styles.wrapper} className={full ? "full-width large" : "about"}>
      <Link to={`/${link}`} aria-label={title}>
        <Box sx={styles.imageWrap} className={full ? "large" : "squared"}>
          <Img fluid={image} />
        </Box>
      </Link>
    </Box>
  );

  switch (previewType) {
    case "stories":
      return stories();
    case "homeBig":
      return homeBig();
    case "shop":
      return shop();
    case "about":
      return about();
    case "relatedPosts":
      return relatedPosts();
    case "relatedPostsWide":
      return relatedPostsWide();
    default:
      return stories();
  }
};

export default BlogPostPreview;

// {!relatedPosts && <h4>Read More</h4>}
