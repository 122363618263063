const styles = {
  blogStyleWrapper: {
    ".meta-wrap": {
      "flex-direction": "column",
      justifyContent: "space-between",
      height: "175px",
      "@media screen and (min-width: 400px)": {
        height: "165px",
      },
      "@media screen and (min-width: 650px)": {
        height: "154px",
      },
    },
    ".sub-meta": {
      width: "100%",
      alignItems: "flex-start",
      justifyContent: "space-between",
      "flex-direction": ["column", "row"],
    },
    ".title": {
      fontSize: ["20px", "23px"],
      paddingTop: 0,
    },
  },
  wrapper: {
    display: "flex",
    "flex-direction": "column",
    cursor: "pointer",
    pointerEvents: "all" as any,
    ".product-image": {
      "object-fit": "cover",
    },
    ".add-cart-button": {
      border: "none",
      color: "white",
      backgroundColor: "#F69D0D",
      borderRadius: "5px",
      padding: "none",
      svg: {
        height: "28px",
        width: "28px",
      },
      "&:hover": {
        backgroundColor: "#F69D0D",
      },
    },
    "&.active": {
      cursor: "default",
      ".gatsby-image-wrapper, .content": {
        opacity: 0.4,
      },
      h4: {
        textOverflow: "initial",
        "-webkit-line-clamp": "initial",
      },
    },
    "&.disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
      pointerEvents: "none" as any,
    },
    a: {
      textDecoration: "none",
    },
    "&.large": {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      "@media screen and (max-width:650px)": {
        margin: "0 10px",
      },
      ".content": {
        height: "130px",
        "&.shop": {
          height: "140px",
          width: "100%",
          "@media screen and (max-width: 1065px)": {
            maxWidth: "200px",
          },
          "@media screen and (max-width: 717px)": {
            maxWidth: "280px",
          },
          "@media screen and (max-width: 600px)": {},
        },
      },
      ".title": {
        fontSize: "18px",
        letterSpacing: ".83px",
      },
    },
    "&.xs": {
      width: "90%",
      maxWidth: "500px",
      "text-align": "left",
      marginRight: "20px",
      "@media screen and (min-width:661px)": {
        maxWidth: "90%",
      },
      "@media screen and (min-width:840px)": {
        maxWidth: "100%",
      },
      ".content": {
        width: "95%",
        "@media screen and (min-width:590px)": {
          //width: "190px"
        },
      },
      ".title": {
        fontSize: "20px",
      },
    },
    "&.small": {
      width: "100%",
      minWidth: "225px",
      "@media screen and (min-width:1330px)": {
        width: "100%",
      },
      ".content": {
        height: "200px",
        width: "85%",
        "@media screen and (min-width:1330px)": {
          maxWidth: "225px",
        },
      },
      ".title": {
        fontSize: "24px",
      },
    },
    "&.search-wrap": {
      width: "100%",
      minWidth: "140px",
      "@media screen and (min-width:1330px)": {
        width: "140px",
      },
      ".content": {
        height: "130px",
        width: "150px",
      },
      ".title": {
        fontSize: "18px",
      },
    },
    "&.med": {
      width: "100%",
      margin: "0 auto",
      "@media screen and (min-width:826px)": {
        maxWidth: "270px",
      },
      ".content": {
        height: "170px",
        width: "90%",
        "@media screen and (min-width:826px)": {
          maxWidth: "230px",
        },
      },
      ".title": {
        fontSize: "21px",
      },
      "&.content-grid": {
        width: "100%",
        maxWidth: "100%",
        "@media screen and (min-width:1024px)": {
          maxWidth: "270px",
        },
        ".content": {
          height: "auto",
          // marginBottom: "20px",
        },
      },
    },
    ".content": {
      "flex-direction": "column",
      justifyContent: "space-between",
      flexGrow: 1,
    },
  },
  title: {
    fontWeight: "500",
    lineHeight: "30px",
    fontFamily: "heading",
    paddingTop: ".9em",
    color: "cgTeal",
    overflow: "hidden",
    "text-align": "left",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "2",
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    "&.blog-style": {
      "-webkit-line-clamp": "2",
    },
    "&.content-grid": {
      fontSize: ["22px !important", "25px !important"],
      lineHeight: "35px",
      paddingTop: "10px",
    },
  },
  subtitle: {
    fontSize: "15px !important",
    fontFamily: "body",
    color: "primaryDarkGrey",
    paddingTop: "12px",
    fontStyle: "italic",
    marginTop: "-2px",
    marginBottom: "0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "1",
    WebkitBoxOrient: "vertical",
    display: " -webkit-box",
  },
  imageWrapper: {
    marginBottom: 0,
    "&.xl": {
      marginBottom: 12,
      img: {
        maxWidth: "100%",
        height: "auto",
      },
    },
    "&.large": {
      width: "100%",
      marginBottom: "5px",
      ".gatsby-image-wrapper": {
        width: "100%",
        height: "300px",
        "@media screen and (max-width:1060px)": {
          height: "250px",
        },
        "@media screen and (max-width:1400px)": {
          height: "250px",
        },
      },
      ".product-image": {
        height: "200px",
        width: "90%",
      },
    },
    "&.xs": {
      ".gatsby-image-wrapper": {
        height: "300px",
        width: "100%",
        "@media screen and (min-width:590px)": {
          height: "200px",
        },
      },
      ".product-image": {
        height: "300px",
        width: "100%",
        "@media screen and (min-width:590px)": {
          height: "200px",
        },
      },
    },
    "&.small": {
      ".gatsby-image-wrapper": {
        height: "290px",
        width: "86%",
        "@media screen and (min-width:600px)": {
          height: "245px",
        },
      },
      ".product-image": {
        height: "290px",
        width: "86%",
        "@media screen and (min-width:600px)": {
          height: "245px",
          width: "210px",
        },
      },
    },
    "&.med": {
      ".gatsby-image-wrapper": {
        height: "300px",
      },
      ".product-image": {
        height: "300px",
        width: "100%",
      },
    },
    "&.extra-small": {
      marginBottom: 0,
      ".gatsby-image-wrapper": {
        height: "140px",
        width: "150px",
        "@media screen and (max-width:407px)": {
          height: "290px",
          width: "100%",
        },
      },
      ".product-image": {
        height: "140px",
        width: "150px",
        "@media screen and (max-width:407px)": {
          height: "290px",
          width: "100%",
        },
      },
    },
    a: {
      "& >div:first-of-type": {
        "& >div:first-of-type": {
          paddingBottom: "130% !important",
        },
      },
    },
    "@media screen and (max-width:1024px)": {
      width: "100%",
      height: "auto",
    },
  },
  cart: {
    position: "absolute" as "absolute",
    top: "5px",
    right: "5px",
    "&.isActive": {
      "> button": {
        opacity: 0,
        visibility: "hidden" as "hidden",
        transform: "scale(0.7)",
      },
      div: {
        opacity: 1,
        visibility: "visible" as "visible",
        transform: "scale(1)",
      },
    },
    "button.active": {
      color: "white",
      fontSize: 0,
      fontWeight: "700",
      backgroundColor: "primary",
      ":hover": {
        backgroundColor: "primary",
      },
    },
  },
  cartBtn: {
    opacity: 1,
    visibility: "visible" as "visible",
    transform: "scale(1)",
    marginLeft: "auto",
    transition: "all 0.2s ease",
    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
    },
  },
  cartCounter: {
    transform: "scale(0.7)",
    position: "absolute" as "absolute",
    top: 0,
    right: 0,
    opacity: 0,
    visibility: "hidden" as "hidden",
    display: "flex",
    alignItems: "center",
    minWidth: [106, 140],
    minHeight: [36, 40],
    borderRadius: 6,
    overflow: "hidden",
    backgroundColor: "primary",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.16)",
    transition: "all 0.2s ease",
    div: {
      width: 50,
      color: "white",
      "text-align": "center",
      fontSize: 0,
      fontWeight: "700",
      paddingLeft: 0,
      paddingRight: 0,
    },
    button: {
      padding: 0,
      minWidth: [32, 45],
      minHeight: [36, 40],
      svg: {
        width: 24,
        height: 24,
        marginLeft: 0,
      },
    },
  },
  meta: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    minWidth: "unset",
    gridColumnGap: "10%",
  },
  column: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "space-between",
  },
  price: {
    fontWeight: "500",
    fontSize: "18px",
    color: "cgTeal",
    lineHeight: "22px",
    fontFamily: "heading",
    "&.blog-style": {
      display: "block",
      paddingTop: "6px",
      color: "cgGold",
      fontSize: ["16px", "17px"],
      marginLeft: [0, "10px"],
    },
  },
  prevPrice: {
    color: "darkgray",
    fontSize: 0,
    marginLeft: 11,
  },
};

export default styles;
