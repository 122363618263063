/** @jsx jsx */

import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { Box, jsx } from "theme-ui";
import ContentContainer from "../../../container/content-container";
import SocialLink from "../../../social-links/social-links";
import styles from "./footer.styles";
import MailChimpForm from "../../../mailchimp/mailchimp";

const footerStaticQuery = graphql`
  query {
    allPrismicCommon {
      edges {
        node {
          data {
            social_links {
              social_type
              social_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const getSocialData = (links) => {
  const data = [];
  links.forEach((link) => {
    data.push({ type: link.social_type, link: link.social_link.url });
  });
  return data;
};

const Footer: React.FC<{ fluid?: boolean }> = () => (
  <StaticQuery<GatsbyTypes.Query>
    query={`${footerStaticQuery}`}
    render={(data) => {
      const footerData = data.allPrismicCommon.edges[0].node.data.social_links
      
      return (
        <Box sx={styles.footer} as="footer">
          <ContentContainer>
          <div sx={styles.newsletter}>
            <MailChimpForm />
          </div>
          <div sx={styles.social}>
            <SocialLink items={footerData}></SocialLink>
          </div>
          <hr />
          <ul sx={styles.links}>
            <li>
              <Link to={"/terms"}>Terms</Link>
            </li>
            <li>
              <Link to={"/faq"}>FAQs</Link>
            </li>
            <li>
              <Link to={"/privacy-policy"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/contact"}>Contact</Link>
            </li>
          </ul>
          </ContentContainer>
        </Box>
      );
    }}
  />
);

export default Footer;
