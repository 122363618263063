/** @jsx jsx */
import { Box, jsx } from "theme-ui";

type PropsType = {
  children: any;
  width?: string;
};

const ContentContainer: React.FC<PropsType> = ({ children, width }) => (
  <Box
    sx={{
      width: width ? width : "100%",
      /* maxWidth: "1650px", */
      margin: "0 auto !important",
      padding: "0 0",
      "@media only screen and (min-width: 650px)": {
        padding: "0 0",
      },
    }}
  >
    {children}
  </Box>
);

export default ContentContainer;
