const styles = {
  // Styles for overlay style post previews
  overlayWrap: {
    padding: 0,
    margin: "0",
    h2: {
      lineHeight: "1em",
      "&.padded": {
        "@media only screen and (min-width: 890px)": {
          paddingBottom: ".6rem",
        },
      },
    },
    a: {
      textDecoration: "none",
      color: "headingColor",
    },
    "&.homeSm": {
      marginTop: "20px",
      width: ["100%", "100%", "49%"],
      "&.gallery:nth-of-type(even)": {
        "&:not(:last-of-type)": {
          paddingBottom: "60px",
          "@media only screen and (max-width: 1023px)": {
            paddingBottom: "5px",
          },
        },
      },
      "&.singleRow:first-of-type": {
        "@media only screen and (max-width: 1023px)": {
          paddingBottom: "5px",
        },
      },
      "&.gallery:not(:last-of-type)": {
        "@media only screen and (max-width: 1023px)": {
          paddingBottom: "5px",
        },
      },
    },
  },
  overlayText: {
    color: "headingColor",
    padding: "20px 0",
  },
  // Styles for non-overlay post previews
  wrapper: {
    margin: 0,
    width: "500px",
    maxWidth: "100%",
    a: {
      textDecoration: "none",
      color: "subHeadingColor",
    },
    h2: {
      marginTop: "1.2rem",
      marginBottom: ".4rem",
      lineHeight: "1.1em",
      color: "headingColor",
    },
    "&.about": {
      width: ["100%", "100%", "47%"],
      "&:first-of-type": {
        "@media only screen and (max-width: 1023px)": {
          paddingBottom: "40px",
        },
      },
    },
    "&.full-width": {
      width: "100%",
    },
    "&.home-big": {
      "@media only screen and (max-width: 890px)": {
        "flex-direction": "column",
      },
    },
    ".info-wrap": {
      display: "flex",
      "flex-direction": "column",
      width: "100%",
      justifyContent: "space-between",
      "text-align": "center",
      order: "-1" as any,
      paddingBottom: "20px",
      "&.reverse": {
        order: "-1" as any,
        paddingLeft: 0,
        "@media only screen and (min-width: 890px)": {
          paddingRight: "20px",
          "text-align": "left",
        },
      },
      "@media only screen and (min-width: 890px)": {
        paddingLeft: "20px",
        order: 1 as any,
        "text-align": "left",
        paddingBottom: 0,
        "&.home": {
          width: "28%",
        },
        "&.related": {
          width: "60%",
        },
      },
    },
    "&.padded": {
      padding: "0 20px",
    },
    ".padded-wrap": {
      padding: "15px 18px 25px",
      "@media only screen and (min-width: 1100px)": {
        padding: "10px 18px 20px",
      },
      "&.delete": {
        padding: 0,
      },
    },
    "&.stories": {
      width: ["100%", "100%", "47%"],
      "&:first-of-type": {
        "@media only screen and (max-width: 1023px)": {
          paddingBottom: "40px",
        },
      },
      p: {
        "@media only screen and (max-width: 889px)": {
          marginBottom: 0,
        },
      },
    },
    "&.short": {
      width: ["100%", "100%", "51%"],
    },
    "&.stories:nth-of-type(2n)": {
      ".hidden": {
        visibility: "hidden" as "hidden",
        "@media only screen and (max-width: 1023px)": {
          display: "none",
        },
      },
    },
    "&.archive": {
      paddingBottom: "80px",
      "@media only screen and (max-width: 1023px)": {
        paddingBottom: "40px",
      },
      "&:last-of-type": {
        paddingBottom: "20px",
      },
      "&.short": {
        paddingBottom: "50px",
      },
      "&.short:last-of-type": {
        paddingBottom: "30px",
      },
      ".hidden": {
        display: "none",
      },
    },
  },
  // Shared styles
  excerpt: {
    fontSize: "16px",
    lineHeight: "26px",
    paddingTop: "20px",
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    WebkitBoxOrient: "vertical",
    "&.related": {
      "@media only screen and (min-width: 1100px)": {
        "-webkit-line-clamp": "3",
      },
    },
    p: {
      marginTop: ".6rem",
      lineHeight: "1.3rem",
    },
  },

  imageWrap: {
    "object-fit": "cover",
    ".gatsby-image-wrapper": {
      width: "100%",
    },
    "&.xs": {
      padding: "10px 18px 20px",
      ".gatsby-image-wrapper": {
        height: "300px",
        "@media only screen and (max-width: 560px)": {
          height: "220px",
        },
        "@media only screen and (max-width: 890px)": {
          height: "250px",
        },
      },
      "&.delete": {
        padding: "0",
        margin: "15px 0",
      },
    },
    "&.sm": {
      ".gatsby-image-wrapper": {
        height: "330px",
        "@media only screen and (max-width: 560px)": {
          height: "220px",
        },
      },
    },
    "&.large": {
      ".gatsby-image-wrapper": {
        height: "350px",
        "@media only screen and (max-width: 560px)": {
          height: "220px",
        },
      },
    },
    "&.wide": {
      width: "100%",
      ".gatsby-image-wrapper": {
        height: "500px",
        "@media only screen and (max-width: 560px)": {
          height: "220px",
        },
      },
      "@media only screen and (min-width: 890px)": {
        width: "80%",
      },
    },
    "&.squared": {
      ".gatsby-image-wrapper": {
        height: "440px",
        //borderRadius: "8px",
        "@media only screen and (max-width: 560px)": {
          height: "220px",
        },
      },
    },
    "&.related-wide": {
      width: "40%",
      minWidth: "400px",
      "@media only screen and (max-width: 890px)": {
        width: "100%",
        minWidth: "100%",
      },
    },
  },
  catHeading: {
    display: "inline-block",
    color: "cgGold",
    fontSize: "18px",
    marginBottom: ".8rem",
    "&.md": {
      fontSize: "16px",
    },
    "&.padded": {
      paddingBottom: ".5rem",
      "@media only screen and (min-width: 560px)": {
        paddingBottom: "1.3rem",
      },
    },
    "@media only screen and (min-width: 560px)": {
      fontSize: "23px",
      "&.md": {
        fontSize: "20px",
      },
    },
  },
  title: {
    fontWeight: "400",
    "&.sm": {
      fontSize: "20px",
      marginTop: ".9rem",
      marginBottom: 0,
    },
    "&.md": {
      fontSize: "26px",
      "@media only screen and (min-width: 560px)": {
        fontSize: "32px",
      },
      "@media only screen and (min-width: 890px)": {
        fontSize: "2rem",
      },
    },
    "&.spaced.md": {
      "@media only screen and (min-width: 890px)": {
        fontSize: "1.9rem",
      },
    },
    "&.lg": {
      fontSize: "26px",
      "@media only screen and (min-width: 560px)": {
        fontSize: "36px",
      },
      "@media only screen and (min-width: 890px)": {
        fontSize: "39px",
      },
    },
    "&.xl": {
      fontSize: "26px",
      "@media only screen and (min-width: 560px)": {
        fontSize: "38px",
      },
      "@media only screen and (min-width: 890px)": {
        fontSize: "42px",
      },
    },
    "&.spaced": {
      paddingTop: "6px",
    },
  },
  btnMore: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: ".4rem",
    gap: "5px",
    "&.related": {
      paddingTop: ".8rem",
    },
  },
  readMore: {
    display: "inline-block",
    transition: ".2s",
    color: "cgTeal",
    cursor: "pointer",
    fontSize: ["13px", "14px", "15px"],
    fontWeight: 500,
    lineHeight: "22px",
    textDecoration: "none",
    "text-align": "center",
    "text-transform": "uppercase",

    p: {
      "text-decoration": "none",
    },
    "&.lg": {
      fontSize: "15px",
      "@media only screen and (min-width: 560px)": {
        fontSize: "20px",
      },
      "@media only screen and (min-width: 1080px)": {
        fontSize: "24px",
      },
    },
    "&.stories": {},
    "&.related": {
      fontSize: ["12px", "13px", "14px"],
    },
    ":hover": {
      color: "cgGold",
    },
    "@media only screen and (min-width: 560px)": {
      fontSize: "18px",
    },
  },
};

export default styles;
