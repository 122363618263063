import React, { useState } from 'react';
import { StyledGiftNote, NoteModal, Checkbox } from "./gift-note.style";
import Add from "../../images/add.inline.svg";
import Pencil from "../../images/pencil.inline.svg";
import Trash from "../../images/trash.inline.svg";

const AboutPage: React.FC<{}> = ({ noteText, setNoteText }) => {
  const [openNote, setOpenNote] = useState(false);
  const [checked, setChecked] = useState(false);

  const buttonHandler = () => {
    setOpenNote(!openNote);
    setChecked(noteText !== "");
  }
  const deleteHandler = () => {
    setNoteText('')
    setChecked(noteText === "");
  }
  return (
    <StyledGiftNote>
      <Checkbox checked={checked}>
        <span className="add">{checked ? `Gift note added!` : `Add a gift note?`}</span>
        {checked ? 
          <>
            <button onClick={() => setOpenNote(!openNote)}>
              <Pencil />
              <span>Edit</span>
            </button>
            <button className="delete" type="reset" onClick={() => deleteHandler()}>
              <Trash />
              <span>Delete</span>
            </button>
          </>
          :
          <button onClick={() => setOpenNote(!openNote)}>
            <Add />
            <span>Add</span>
          </button>
        }
      </Checkbox>
      { openNote && ( <NoteModal open={openNote}>
        <div className="note-box">
          <h3>Add a gift message</h3>
          <form
            className="form"
            name="Gift Note"
          >
            <label htmlFor="message"></label>
            <textarea
              className="input"
              required={true}
              placeholder="Your message"
              name="message"
              id="message"
              value={noteText}
              onChange={event => setNoteText(event.target.value)}
            />
            <div className="buttons">
              <button
                className="button add right"
                type="button"
                onClick={() => buttonHandler()}
              >
                <Add />
                <span>Save</span>
              </button>
              <button
                className="button save"
                type="reset"
                onClick={() => setNoteText('')}
              >
                <span>Clear</span>
              </button>
            </div>
          </form>
        </div>
      </NoteModal>)}
    </StyledGiftNote>
  );
};
export default AboutPage;