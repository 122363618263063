const styles = {
  wrapper: {
    width: "100%",
    margin: "0 auto",
    paddingTop: "3px",
    paddingBottom: "3px",
    "&:not(.related)": {
      paddingX: "0",
    },
    /* ".carousel-button-group": {
      opacity: 1,
      visibility: "visible" as "visible",
      transition: "all 0.25s ease",
    }, */
    button: {
      top: "30% !important",
    },
    /* "&.related": {
      "@media screen and (min-width:1330px)": {
        ".carousel-button-group": {
          display: "none",
        },
      },
    }, */
    "@media screen and (max-width:1080px)": {
      width: "100%",
      /* ".carousel-button-group": {
        opacity: 0,
        visibility: "hidden" as "hidden",
      },
      "&:hover": {
        ".carousel-button-group": {
          opacity: 0,
          visibility: "hidden" as "hidden",
        },
      }, */
    },
  },
};

export default styles;
