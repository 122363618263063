/** @jsx jsx */
import { useContext, useState, useEffect } from "react";
import Fuse from "fuse.js";
import { Box, Text, Image, Flex, jsx } from "theme-ui";
import ProductGrid from "../product-grid/product-grid";
import { SearchContext } from "../../provider/search-provider";
import styles from "./search.style";
import { StyledInput } from "./input.style";
import BlogPostPreview from "../blog/blog-post-preview";
import FlexWrapper from "../container/flex-wrapper";
import pinkShape from "../../images/pink_shape.png";
import { TextCheck } from "../../utils";
import Icon from "../category-icon";

type PropsType = {
  allShopifyProduct: any;
  allStories: any;
  setSearched: any;
};

const fuseOptions = {
  isCaseSensitive: false,
  findAllMatches: false,
  includeMatches: false,
  includeScore: false,
  useExtendedSearch: false,
  minMatchCharLength: 4,
  shouldSort: true,
  threshold: 0.15, // lower threshold value means more accurate results
  location: 0,
  distance: 3000,
  keys: [
    "node.title",
    "node.tags",
    "node.description",
    "node.description.text",
    "node.ingredients.text",
    "node.story.text",
    "node.uid",
    "node.tags",
    "node.data.excerpt",
    "node.data.body.primary.text_block.text",
    "node.data.body.primary.card_text.text",
    "node.data.body.items.card_text.text",
    "node.data.body.primary.quote.text",
  ],
};

const Search: React.FC<PropsType> = (props) => {
  const { allShopifyProduct } = props;
  const { allStories } = props;
  const { setSearched } = props;
  const { allProducts } = props;
  const { allParent_products } = props;

  const stories = allStories?.edges || [];
  const products = allShopifyProduct?.edges || [];

  if (!products) {
    return;
  }

  let productVariants = [];
    products.forEach((product) => {
      const { title, handle, variants, description, tags, images } = product.node;
      if (!tags.some(tag => tag === "All Events")) {
        variants.forEach((variant) => {
          const matchingSkus = allProducts.edges.find(
            (edge) => edge.node.sku === variant.sku
          );
          const variantInfo = matchingSkus?.node
          const parent = variantInfo ? variantInfo.parent_product : allParent_products.edges.find(
            (edge) => edge.node.parent_title === product.title
          );
          const parentInfo = parent?.node ? parent.node : parent
        
          const productInfo = {
            details: TextCheck(variantInfo?.details) || TextCheck(parentInfo?.details),
            ingredients: TextCheck(variantInfo?.ingredients) || TextCheck(parentInfo?.ingredients),
            story: TextCheck(variantInfo?.story) || TextCheck(variantInfo?.vendor?.description) || TextCheck(parentInfo?.vendor?.description),
          };
          let node = {}
          node = {
            title,
            isVariant: variants.length > 1 && true,
            handle,
            story: productInfo.story,
            ingredients: productInfo.ingredients,
            description: productInfo.details || description,
            sku: variant.sku,
            id: variant.shopifyId,
            price: variant.price,
            image: variant.image || images[0],
            available: variant.availableForSale,
            subtitle: variant.title,
          };
          productVariants.push({node});
        });
      }
    });
  
  const activeVariants = productVariants?.filter(variant => variant.node.available === true)
  
  const [value, setValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [noResult, setNoResult] = useState(false);
  const { setIsSearched } = useContext(SearchContext);

  // Make an array with product and story content
  const allContent = activeVariants.concat(stories);
  
  // Do search
  const fuse = new Fuse(allContent, fuseOptions);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (value.length > 0) {
      setIsSearched(true);
    }
    return () => setIsSearched(false);
  }, [value]);

  useEffect(() => {
    if (value.length > 0) {
      setSearched(true);
    }
    return () => setSearched(false);
  }, [value]);

  useEffect(() => {
    const handler = setTimeout(() => {
      let searchData: any = [];
      if (value.length) {
        const fuseData = fuse.search(value);
        if (fuseData && fuseData.length) {
          fuseData.forEach((data) => {
            searchData.push(data.item);
          });
        }
      }
      setSearchData(searchData);
    }, 500);
    return () => clearTimeout(handler);
  }, [value]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (value.length && !searchData.length) {
        setNoResult(true);
      } else {
        setNoResult(false);
      }
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [value, searchData]);

  const handleClear = () => {
    setValue("");
    setNoResult(false);
    setSearched(false);
    setFocused(false);
  };

  const handleInput = () => {
    document.getElementById("seach_bar")?.focus();
  };
  let productResults = searchData.filter(function (e) {
    return e.node.id;
  });
 
  let storyResults = searchData.filter(function (e) {
    return e.node.data?.post_title;
  });
  
  return (
    <Box className="picksySearch" sx={styles.wrapper}>
      <StyledInput>
        <div className="container">
          <label htmlFor="search_bar" className="label">
            Search
          </label>
          <input
            className="search_bar"
            name="search_bar"
            id="seach_bar"
            type="search"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={() => setFocused(true)}
            placeholder=""
            autoComplete="off"
          />
          {focused ? (
            <div onClick={() => handleClear()}>
              <Icon name="close" />
            </div>
            
          ) : (
            <div onClick={() => handleInput()}>
              <Icon name="search" />
            </div>
          )}
        </div>
      </StyledInput>

      {value && searchData.length ? (
        <Box className="searchResult" sx={styles.searchResult}>
          <Box
            sx={{
              maxWidth: "1350px",
              margin: "0 auto !important",
              padding: "0 10px",
              "@media only screen and (min-width: 650px)": {
                padding: "0 25px",
              },
            }}
          >
            {productResults && (
              <ProductGrid
                gridTitle="Search Result"
                products={productResults}
                search={true}
              />
            )}
            <FlexWrapper padded>
              {storyResults &&
                storyResults.map((post) => {
                  return (
                    <BlogPostPreview
                      content={post.node}
                      previewStyle="stories"
                      archive
                      hideCat
                      key={post.node.uid}
                    />
                  );
                })}
            </FlexWrapper>
          </Box>
        </Box>
      ) : null}
      {value && noResult && (
        <Box className="notFound searchResult" sx={styles.searchResult}>
          <Text as="h1">
            No items found!
            <Image src={pinkShape}></Image>
          </Text>
          <p>Sorry, no items were found for your search.</p>
        </Box>
      )}
    </Box>
  );
};

export default Search;
