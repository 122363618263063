import React from 'react';
import { NoteModal } from "./gift-note.style";
import Gift from "../../images/gift.inline.svg";

const Popup: React.FC<{}> = ({ closeCart, checkout, noteText }) => {
  const handleCheckout = () => {
    checkout();
    closeCart();
  };
  
  return (
    <NoteModal open={true}>
      <div className="note-box confirmation">
        <Gift />
        <div className="inner-wrap">
          <p className="intro">Your gift note will be added to your package!</p>
          <p>"{noteText}"</p>
        </div>
         <button className="button popup" onClick={handleCheckout}>
           <span>Go to checkout</span>
        </button>
      </div>
    </NoteModal>
  );
};
export default Popup;