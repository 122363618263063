import React from "react";
import Icon from "../category-icon";


type PropsType = {
  item: {
    type: string;
    link: string;
  };
};

const SocialLink: React.FC<PropsType> = ({ item }) => {
  const type = item.social_type
  const link = item.social_link.url

  const LinkItem = () => {
    switch (type) {
      case "Facebook":
        return (
          <a
            href={link}
            data-tip={type}
            title={type}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="facebook" />
          </a>
        );
      case "Twitter":
        return (
          <a
            href={link}
            data-tip={type}
            title={type}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="twitter" />
          </a>
        );
      case "Instagram":
        return (
          <a
            href={link}
            data-tip={type}
            title={type}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="instagram" />
          </a>
        );

      default:
        return null;
    }
  };

  return (
    <LinkItem />
  );
};

export default SocialLink;
