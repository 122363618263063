const styles = {
  mailchimp: {
    paddingTop: "8px",
    position: "relative",
    justifyContent: "space-between",
    "flex-direction": "column",
    ".button-group": {
      "@media screen and (max-width: 590px)": {
        width: "100%"
      }
    },
    "@media screen and (max-width: 590px)": {
      alignItems: "center",
      paddingTop: "0",
    },
    ".label": {
      height: 0,
      overflow: "hidden",
      position: "absolute",
    },
    ".subscribe": {
      display: "flex",
      height: "40px",
      alignItems: "center",
      "@media screen and (max-width: 590px)": {
        "flex-direction": "column",
        height: "auto",
        width: "100%",
      },
    },
    h3: {
      color: "#003865",
      fontSize: ["28px", "44px", "44px", "48px"],
      letterSpacing: "1px",
      lineHeight: "1em",
      fontFamily: "body",
      fontWeight: "300",
      "text-align": "left",
      textTransform: "uppercase",
      marginBottom: "10px",
      span: {
        fontWeight: 700,
        display: "block",
      },
      "@media screen and (max-width: 590px)": {
        "text-align": "center"
      }
    },
    ".text": {
      color: "#003865",
      fontSize: ["20px", "22px", "26px"],
      fontWeight: "400",
      marginBottom: "20px",
      lineHeight: "1em",
      "@media screen and (max-width: 590px)": {
        marginTop: 0,
        "text-align": "center"
      },
    },
    ".note": {
      fontFamily: "heading",
      "text-align": "right",
      fontSize: "15px",
      color: "cgTeal",
      marginBottom: 0,
      lineHeight: "1em",
      "@media screen and (max-width: 590px)": {
        "text-align": "center"
      },
    },
    ".no-thanks": {
      fontSize: "15px",
      color: "cgTeal",
      border: "none",
      position: "absolute",
      "&.mobile": {
        display: "none",
        "@media screen and (max-width: 590px)": {
          position: "relative",
          margin: "20px auto 0",
          display: "block"
        },
      },
      "&.desktop": {
        left: "-5px",
        bottom: 0,
        "@media screen and (max-width: 590px)": {
          display: "none",
        },
      },
    },
    ".input": {
      height: "100%",
      border: "none",
      marginLeft: "12px",
      minWidth: "200px",
      flex: 1,
      "text-align": "center",
      padding: 0,
      font: "300 18px/32px 'Montserrat', Sans-serif",
      color: "#fff",
      backgroundColor: "#EFD19F",
      outline: "none",
      marginRight: "8px",
      "@media screen and (max-width: 890px)": {
        width: "290px",
      },
      "@media screen and (max-width: 590px)": {
        width: "100%",
        "text-align": "center",
        marginBottom: "12px",
        height: "30px"
      },
    },
    ".email-title": {
      fontSize: ["22px", "25px"],
      fontFamily: "body",
      fontWeight: "600",
      color: "cgTeal",
      lineHeight: "36px",
      "@media screen and (max-width: 890px)": {
        display: "block",
        "text-align": "right",
      },
      "@media screen and (max-width: 590px)": {
        "text-align": "center",
        display: "none"
      },
    },
  },
  buttonStyle: {
    button: {
      color: "cgTeal",
      padding: "8px 0",
      backgroundColor: "cgFushia",
      border: "none",
      outline: "none",
      width: "130px",
      height: "100%",
      borderRadius: "6px",
      fontSize: ["22px", "25px"],
      fontWeight: 600,
      lineHeight: "22px",
      transition: "all 0.2s ease",
      "@media screen and (max-width: 590px)": {
        width: "100%"
      }
    },
  },
  message: {
    display: "inline-block",
    fontSize: "22px",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
};

export default styles;