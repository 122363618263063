const styles = {
  main: {
    minHeight: "100vh",
    width: "100vw",
    "&#shop-main": {
      overflow: "hidden",
    },
    ">.container": {
      flexGrow: 1,
      paddingTop: ["123px", "143px"],
      margin: "0 auto",
    },
    ".fluid": {
      width: "100%",
      marginLeft: 0,
      "@media screen and (min-width: 1920px)": {
        marginLeft: 0,
      },
      "@media screen and (max-width: 1024px)": {
        marginLeft: 0,
        width: "100%",
      },
    },
    "& button": {
      backgroundColor: "transparent",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  searchedMain: {
    height: "100vh",
    overflow: "hidden",
  },
  sidebar: {
    width: "260px",
    height: "calc(100vh - 70px)",
    position: "fixed",
    top: "70px",
    left: "0",
    zIndex: 9,
    backgroundColor: "white",
    overflow: "hidden",
    "@media screen and (min-width: 1920px)": {
      width: "300px",
    },
    "@media screen and (max-width: 1024px)": {
      display: "none",
    },
  },
  modalBackground: {
    opacity: 0.6,
    height: "210%",
    transition: "opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)",
    background: "rgba(0, 0, 0, 0.2)",
    width: "100%",
    position: "fixed" as "fixed",
    top: 0,
    left: 0,
    zIndex: 50000,
  },
};

export default styles;
