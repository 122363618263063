import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./product-modal.style";
import React from "react";
import { Box } from "theme-ui";
import useFreezeBodyScroll from "../../hooks/useFreezeBodyScroll";

type Props = {
  togglePopup: boolean;
  setTogglePopup: (arg0: boolean) => void;
  title: string;
  handleCart: Function;
  productPage?: boolean;
};

const ProductAddedModal = ({
  togglePopup,
  setTogglePopup,
  title,
  handleCart,
  productPage,
}: Props) => {
  const handleClose = () => {
    setTogglePopup(false);
  };
  const handleBack = () => {
    setTogglePopup(false);
    if (productPage) {
      history.back();
    }
  };
  useFreezeBodyScroll(togglePopup);

  return (
    <Box sx={styles.wrapper}>
      <Box
        id="notificationBar"
        className={
          togglePopup ? "animation notificationBar" : "notificationBar"
        }
      >
        <button className="close" onClick={() => handleClose()}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <span className="text">
          <p className="wrap">
            {" "}
            <FontAwesomeIcon icon={faCheck} /> {title} was added to the cart!
          </p>
        </span>
        <div className="buttons">
          <button className="cart" onClick={() => handleCart()}>
            Go to cart
          </button>
          <button className="shop" onClick={() => handleBack()}>
            Continue shopping
          </button>
        </div>
      </Box>
    </Box>
  );
};

export default ProductAddedModal;
