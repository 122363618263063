const styles = {
  socialLinks: {
    display: "flex",
    alignItems: "center",
    svg:{ 
      height: "23px",
      display: "block",
      margin: "auto",
      transition: "all .05s ease"
    },
    a: {
      display: "flex",
      height: "40px",
      border: "2px solid white",
      borderRadius: "50%",
      width: "40px",
      alignItems: "center",
      color: "#fff",
      marginLeft: "16px",
      textDecoration: "none",
      ":hover": {
        borderColor: "#D79A2B",
        color: "cgGold",
      },
    },
  },
};

export default styles;
