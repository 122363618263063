/** @jsx jsx */
import { FC } from "react";
import { Flex, jsx } from "theme-ui";

interface FlexWrapperProps {
  padded?: boolean;
  children: any;
}

const FlexWrapper: FC<FlexWrapperProps> = ({ padded, children }) => {
  const fullWidth = {
    width: "100%",
    justifyContent: "space-between",
    "flex-wrap": "wrap",
    padding: "20px 0 0",
    "@media only screen and (min-width: 560px)": {
      padding: "45px 0 0",
    },
  };
  const paddedWidth = {
    width: "100%",
    justifyContent: "space-between",
    "flex-wrap": "wrap",
    padding: "20px 0 0",
  };
  return <Flex sx={padded ? paddedWidth : fullWidth}>{children}</Flex>;
};

export default FlexWrapper;
